import { IANAZone, dayjs } from '@shared/utils'
import { useCallMeNowQueueStatus } from './use-call-me-now-queue-status'
import { useFlags } from './use-flags'
import { usePortalDims } from './use-portal-dims'

export const useWelcomeChatAvailability = () => {
  const { isQueueOpen } = useCallMeNowQueueStatus()
  const { welcomeChatExperiment } = useFlags()

  // Only available on weekdays due to limited EC capacity on weekends
  const isTodayAWeekday =
    dayjs().tz(IANAZone.Eastern).day() >= 1 && dayjs().tz(IANAZone.Eastern).day() <= 5

  const { isMobile } = usePortalDims()

  return {
    isWelcomeChatAvailable:
      Boolean(welcomeChatExperiment && isTodayAWeekday && isQueueOpen) && isMobile,
  }
}
