import { Alert, FlagIcon, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
import React from 'react'

export const IntakeTaskExpirationAlert = ({
  expirationDatetime,
  isReferral = false,
}: {
  expirationDatetime: string
  isReferral?: boolean
}) => {
  return (
    <Alert variant='warning' icon={<FlagIcon />}>
      <Text className='print-content' bold color={colors => colors.text[0]}>{`${
        isReferral ? 'This' : 'Your'
      } intake will be canceled if the tasks have not been completed by ${dayjs(
        expirationDatetime,
      ).format('h:mma z')} on ${dayjs(expirationDatetime).format('MM/DD/YYYY')}`}</Text>
    </Alert>
  )
}
