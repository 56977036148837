import React, { PropsWithChildren } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Skeletons } from '../common/components'
import { useAuth } from '../common/hooks'
import { useReferralPartner } from '../common/hooks/use-partner'
import { routes } from '../common/routes'
import * as Pages from './pages'

export const ChatAuth = ({ children }: PropsWithChildren) => {
  const { isLoading, currentUser, isAuthorized } = useAuth()
  const { partnerPath } = useReferralPartner()

  if (isLoading) {
    return <Skeletons />
  }

  if (isAuthorized) {
    /*
     * An authorized user with an incomplete workflow needs to go
     * to /onboarding to complete it
     */
    if (currentUser?.data?.account.requiresOnboarding) {
      return <Navigate replace to={`${routes.onboarding.index}${partnerPath}`} />
    }
  }

  return <>{children}</>
}

export const ChatApp = () => {
  return (
    <Routes>
      <Route
        path={routes.chat.children.workflowSessionId}
        element={
          <ChatAuth>
            <Pages.ChatStarted />
          </ChatAuth>
        }
      />
      <Route path='*' element={<NavigateToHome />} />
    </Routes>
  )
}

const NavigateToHome = () => {
  const { partnerPath } = useReferralPartner()
  return <Navigate replace to={`${routes.welcome.index}${partnerPath}`} />
}
