import {
  Dialog,
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TitleTwo,
} from '@shared/components'
import { getConversationId } from '@shared/utils'
import { setDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Chatbox } from '../../chatBox'
import { Skeletons } from '../../common/components'
import { db, doc, getDoc } from '../../common/firebase'
import { useAuth, useMyMutation, usePortalDims, usePortalQuery } from '../../common/hooks'
import { routes } from '../../common/routes'
export const ChatStarted = () => {
  const { workflowSessionId } = useParams<{ workflowSessionId: string }>()
  const { headerHeight } = usePortalDims()
  const [openSignedOutModal, setOpenSignedOutModal] = useState(false)
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const currentUserData = currentUser?.data

  const [openModal, setOpenModal] = useState(false)
  const [signOutLoading, setSignOutLoading] = useState(false)

  const cancelAppointmentMutation = useMyMutation('PUT /appointments/:id/cancel')

  // get appointment associated with workflow session
  const appointmentQuery = usePortalQuery(
    'GET /appointments/onboarding',
    {
      query: { workflowSessionId: workflowSessionId as string },
    },
    {
      enabled: Boolean(workflowSessionId),
    },
  )

  const appointmentData = appointmentQuery.data

  // todo: update types returned from GET /appointments/onboarding so we don't need to do this.
  const isWelcomeChat =
    appointmentData?.type === 'Free Consultation Call' ||
    appointmentData?.type === 'Returning welcome call'

  if (appointmentQuery.isLoading || !appointmentQuery.data) {
    return <Skeletons />
  }

  if (isWelcomeChat && !appointmentData?.appointmentId) {
    return <Navigate to={routes.welcome.index} />
  }

  const closeChat = async () => {
    setSignOutLoading(true)

    const conversationId = getConversationId({
      userId: currentUserData?.oid,
      appointmentId: isWelcomeChat ? appointmentData.appointmentId : undefined,
    })

    const conversationRef = doc(db, 'conversations', conversationId)

    const conversationDoc = await getDoc(conversationRef)

    if (conversationDoc.exists() && isWelcomeChat) {
      cancelAppointmentMutation.mutate(
        {
          params: { id: appointmentData.appointmentId },
        },
        {
          onSuccess: () => {
            void setDoc(conversationRef, {
              ...conversationDoc.data(),
              active: false,
              redirect: routes.signout.index,
            })
            setSignOutLoading(false)
          },
        },
      )
    }
  }

  return (
    <Dialog
      keepMounted={false}
      opened
      style={{
        padding: 0,
        maxWidth: '100%',
        overflow: 'hidden',
        width: '100vw',
        height: '100%',
      }}
      position={{
        top: headerHeight,
        bottom: 0,
      }}
      p={openModal ? 'md' : 0}
    >
      <Modal
        opened={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <Stack>
          <TitleTwo>Are you sure you want to close the chat?</TitleTwo>
          <Text>You will be signed out.</Text>
          <Group noWrap position='right'>
            <SecondaryButton
              loading={signOutLoading}
              disabled={signOutLoading}
              onClick={() => {
                setOpenModal(false)
              }}
            >
              No
            </SecondaryButton>
            <PrimaryButton loading={signOutLoading} disabled={signOutLoading} onClick={closeChat}>
              Yes, close chat
            </PrimaryButton>
          </Group>
        </Stack>
      </Modal>
      <Modal
        opened={openSignedOutModal}
        onClose={() => {
          navigate('/signout')
        }}
      >
        <Stack>
          <TitleTwo>You have been signed out</TitleTwo>
          <Text>Please sign in to complete your enrollment.</Text>
          <Group noWrap position='center'>
            <PrimaryButton
              fullWidth
              loading={signOutLoading}
              disabled={signOutLoading}
              onClick={closeChat}
            >
              Sign in
            </PrimaryButton>
          </Group>
        </Stack>
      </Modal>
      <Chatbox
        setOpenSignedOutModal={setOpenSignedOutModal}
        appointmentId={isWelcomeChat ? appointmentData.appointmentId : undefined}
        onClose={() => {
          setOpenModal(true)
        }}
      />
    </Dialog>
  )
}
