import {
  ArrowLeftIcon,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
} from '@shared/components'
import React from 'react'

export type PromptContentNavigationProps = {
  onBack: (() => void) | undefined
  onSubmit: () => void
  disableSubmit?: boolean
}

export const PromptContentNavigation = ({
  onBack,
  onSubmit,
  disableSubmit = false,
}: PromptContentNavigationProps) => {
  return (
    <ResponsiveBox
      mobile={
        <Stack spacing='lg' justify='center'>
          <PrimaryButton
            test-id='button:next@mobile'
            fullWidth
            disabled={disableSubmit}
            onClick={onSubmit}
          >
            Next
          </PrimaryButton>
          {onBack && (
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          )}
        </Stack>
      }
      desktop={
        <Group position='apart'>
          {onBack && (
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          )}
          <Group position='right'>
            <PrimaryButton
              test-id='button:next@desktop'
              onClick={onSubmit}
              disabled={disableSubmit}
            >
              Next
            </PrimaryButton>
          </Group>
        </Group>
      }
    />
  )
}
