import { Box } from '@shared/components'
import { WorkflowType } from '@shared/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { WorkflowPrompt } from '../../common/components/prompt/WorkflowPrompt'
import { useReferralPartner } from '../../common/hooks/use-partner'
import { routes } from '../../common/routes'

export const Referrals = () => {
  const { isPartnerRoute, partnerPath, partnerId } = useReferralPartner()
  const navigate = useNavigate()

  let workflowType: WorkflowType = isPartnerRoute
    ? 'patient_referral_form_partner'
    : 'patient_referral_form'

  if (partnerId === 'spring-health') {
    workflowType = 'patient_referral_form_spring_health'
  }

  return (
    <Box test-id='page:referrals'>
      <WorkflowPrompt
        workflowApi='referral'
        workflowType={workflowType}
        showProgress={false}
        showBackButton
        prefix={`${routes.referrals.index}${partnerPath}`}
        onComplete={() => {
          navigate(`${routes.welcome.index}${partnerPath}`)
        }}
      />
    </Box>
  )
}

export default Referrals
