import { z } from 'zod'
import { ApiSchemas } from '../../utils'

const PromptResponseValueSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.array(z.string()),
  z.undefined(),
])

const GetWorkflowPromptResponsesForSessionReqRes = {
  req: z.object({
    params: z.object({
      sessionId: z.string(),
      value: z.string(),
    }),
  }),
  res: z.object({
    data: z.record(z.string(), PromptResponseValueSchema).nullable(),
  }),
}

export const WorkflowPromptResponsesRouteSchemas = {
  'GET /public/workflow-prompt-responses/:sessionId/value/:value':
    GetWorkflowPromptResponsesForSessionReqRes,
} satisfies ApiSchemas
