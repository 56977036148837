import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Checkbox,
  CheckboxGroup,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  Select,
  Stack,
  TertiaryButton,
  TextInput,
  TitleTwo,
  isAnySelected,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmail, isPhone, isRequired } from '../../../forms'
import { useFlags } from '../../../hooks'
import { setSessionStorageItem } from '../../../storage'
import { WidgetProps } from '../PromptContentWidget'

enum PartnershipInterestValues {
  Interest = 'interest',
}

type ReferralContactFormType = {
  referralType: string
  email: string
  phone: string
  organization: string
  partnershipInterest: string[]
}

const FRIEND: PromptResponseContextKey = 'type_of_referral_friend'
const COLLEAGUE: PromptResponseContextKey = 'type_of_referral_colleague'
const SPOUSE_PARTNER: PromptResponseContextKey = 'type_of_referral_spouse_partner'
const FAMILY: PromptResponseContextKey = 'type_of_referral_family'
const PROVIDER: PromptResponseContextKey = 'type_of_referral_provider'
const CORRECTIONAL_FACILITY: PromptResponseContextKey = 'type_of_referral_correctional_facility'
const PROVIDER_WITH_PATIENT: PromptResponseContextKey = 'type_of_referral_provider_with_patient'
const SELF: PromptResponseContextKey = 'type_of_referral_self'

const TypesOfReferrals: { label: string; value: PromptResponseContextKey }[] = [
  {
    label: 'Healthcare provider',
    value: PROVIDER,
  },
  {
    label: 'Family member',
    value: FAMILY,
  },
  {
    label: 'Friend',
    value: FRIEND,
  },
  {
    label: 'Spouse/partner',
    value: SPOUSE_PARTNER,
  },
  {
    label: 'Correctional facility',
    value: CORRECTIONAL_FACILITY,
  },
  {
    label: 'Colleague',
    value: COLLEAGUE,
  },
  {
    label: 'I’m interested in Ophelia for myself',
    value: SELF,
  },
]

export const TypeOfReferral = ({ response, onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const onBack = () => navigate(-1)
  const { sessionId, promptId } = useParams()
  const { correctionsReferralFlow } = useFlags()

  const typesOfReferralsToShow = correctionsReferralFlow
    ? TypesOfReferrals
    : TypesOfReferrals.filter(referral => referral.value !== CORRECTIONAL_FACILITY)

  const form = useForm<ReferralContactFormType>({
    validateInputOnBlur: ['referralType'],
    initialValues: {
      referralType: response?.value.contextKey as PromptResponseContextKey | '',
      email: (response?.provider_email?.value as string) || '',
      phone: (response?.provider_phone?.value as string) || '',
      organization: (response?.provider_organization?.value as string) || '',
      partnershipInterest: response?.partnership_interest?.value
        ? [PartnershipInterestValues.Interest]
        : [],
    },
    validate: {
      email: validateWith(
        skipIfOtherField('referralType', 'is', FAMILY),
        skipIfOtherField('referralType', 'is', FRIEND),
        skipIfOtherField('referralType', 'is', SPOUSE_PARTNER),
        skipIfOtherField('referralType', 'is', COLLEAGUE),
        skipIfOtherField('referralType', 'is', SELF),
        isRequired,
        isEmail,
      ),
      phone: validateWith(
        skipIfOtherField('referralType', 'is', FAMILY),
        skipIfOtherField('referralType', 'is', FRIEND),
        skipIfOtherField('referralType', 'is', SPOUSE_PARTNER),
        skipIfOtherField('referralType', 'is', COLLEAGUE),
        skipIfOtherField('referralType', 'is', SELF),
        isRequired,
        isPhone,
      ),
      organization: validateWith(
        skipIfOtherField('referralType', 'is', FAMILY),
        skipIfOtherField('referralType', 'is', FRIEND),
        skipIfOtherField('referralType', 'is', SPOUSE_PARTNER),
        skipIfOtherField('referralType', 'is', COLLEAGUE),
        skipIfOtherField('referralType', 'is', SELF),
        isRequired,
      ),
      referralType: isAnySelected(typesOfReferralsToShow, 'Required'),
    },
  })

  const friendReferralArray: PromptResponseContextKey[] = [
    FRIEND,
    SPOUSE_PARTNER,
    FAMILY,
    COLLEAGUE,
  ]
  const organizationReferralArray: PromptResponseContextKey[] = [CORRECTIONAL_FACILITY, PROVIDER]

  const submitHandler = ({ isWithPatient = false }: { isWithPatient?: boolean }) => {
    if (form.validate().hasErrors) {
      return
    }
    if (form.values.referralType === SELF) {
      return navigate('/welcome')
    }

    if (isWithPatient && sessionId) {
      setSessionStorageItem('referral_workflow_prompt_response', `${sessionId}_${promptId}`)
    }

    if (friendReferralArray.includes(form.values.referralType as PromptResponseContextKey)) {
      return onSubmit({
        value: {
          contextKey: form.values.referralType as PromptResponseContextKey,
        },
      })
    } else if (form.values.referralType === CORRECTIONAL_FACILITY) {
      onSubmit({
        value: {
          contextKey: CORRECTIONAL_FACILITY,
        },
        provider_phone: {
          contextKey: 'custom',
          value: form.values.phone,
        },
        provider_email: {
          contextKey: 'custom',
          value: form.values.email,
        },
        provider_organization: {
          contextKey: 'custom',
          value: form.values.organization,
        },
      })
    } else {
      onSubmit({
        value: {
          contextKey: isWithPatient ? PROVIDER_WITH_PATIENT : PROVIDER,
        },
        provider_phone: {
          contextKey: 'custom',
          value: form.values.phone,
        },
        provider_email: {
          contextKey: 'custom',
          value: form.values.email,
        },
        provider_organization: {
          contextKey: 'custom',
          value: form.values.organization,
        },
        partnership_interest: {
          contextKey: 'custom',
          value: form.values.partnershipInterest.includes(PartnershipInterestValues.Interest),
        },
      })
    }
  }

  const organizationLabel =
    form.values.referralType === CORRECTIONAL_FACILITY ? 'Facility name' : 'Organization name'

  return (
    <Stack spacing='md'>
      <TitleTwo>First, how are you acquainted with the person you’re referring?</TitleTwo>
      <Select
        data={typesOfReferralsToShow}
        label='Relationship to referral'
        placeholder='Select one...'
        {...form.getInputProps('referralType')}
      />

      {form.values.referralType &&
        organizationReferralArray.includes(
          form.values.referralType as PromptResponseContextKey,
        ) && (
          <Stack spacing='md'>
            <TextInput
              type='email'
              placeholder='Work email'
              label='Work email'
              {...form.getInputProps('email')}
            ></TextInput>
            <PhoneInput label='Work phone' {...form.getInputProps('phone')} />
            <TextInput
              placeholder={organizationLabel}
              label={organizationLabel}
              {...form.getInputProps('organization')}
            ></TextInput>
          </Stack>
        )}
      {form.values.referralType === PROVIDER && (
        <CheckboxGroup {...form.getInputProps('partnershipInterest')}>
          <Checkbox
            value={PartnershipInterestValues.Interest}
            label='I’m interested in partnering with Ophelia to refer more patients'
          />
        </CheckboxGroup>
      )}
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' align='center'>
            <PrimaryButton
              test-id='button:next@mobile'
              fullWidth
              onClick={() => submitHandler({ isWithPatient: false })}
            >
              Next
            </PrimaryButton>
            {form.values.referralType === PROVIDER && (
              <SecondaryButton
                test-id='button:with-the-patient@desktop'
                onClickCapture={() => submitHandler({ isWithPatient: true })}
              >
                I&apos;m with the patient
              </SecondaryButton>
            )}
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <Group position='right'>
              {form.values.referralType === PROVIDER && (
                <SecondaryButton
                  test-id='button:with-the-patient@desktop'
                  onClickCapture={() => submitHandler({ isWithPatient: true })}
                >
                  I&apos;m with the patient
                </SecondaryButton>
              )}
              <PrimaryButton
                test-id='button:next@desktop'
                onClick={() => submitHandler({ isWithPatient: false })}
              >
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
