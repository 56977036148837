import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { isRequired } from '../../../forms'
import { useAuth } from '../../../hooks'
import { WidgetProps } from '../PromptContentWidget'

type Form = {
  careReadiness: PromptResponseContextKey | undefined
}

const READY_TO_START = 'care_readiness_yes' as PromptResponseContextKey
const READY_TO_START_CHAT = 'care_readiness_yes_chat_test' as PromptResponseContextKey
const NOT_READY = 'care_readiness_no' as PromptResponseContextKey
const NOT_READY_CHAT = 'care_readiness_no_chat_test' as PromptResponseContextKey
export const CareReadinessChat = ({ response, onBack, onSubmit }: WidgetProps) => {
  const { currentUser } = useAuth()

  const getInitialValue = (): PromptResponseContextKey | undefined => {
    if (response?.value.contextKey === READY_TO_START_CHAT) {
      return READY_TO_START
    }
    if (response?.value.contextKey === NOT_READY_CHAT) {
      return NOT_READY
    }
    return response?.value.contextKey as PromptResponseContextKey | undefined
  }

  const form = useForm<Form>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      careReadiness: getInitialValue(),
    },
    validate: {
      careReadiness: validateWith(isRequired),
    },
  })

  const submitHandler = () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }
    if (!form.values.careReadiness) {
      return
    }

    const isChatTest =
      currentUser?.data?.experiments?.['welcome-chat-cohort-split'].cohort === 'test'

    if (isChatTest) {
      return onSubmit({
        value: {
          contextKey: `${form.values.careReadiness}_chat_test` as PromptResponseContextKey,
        },
      })
    }

    return onSubmit({
      value: {
        contextKey: form.values.careReadiness,
      },
    })
  }

  return (
    <Stack spacing='lg' test-id='content:care-readiness'>
      <RadioGroup {...form.getInputProps('careReadiness')}>
        <Radio
          test-id='radio:care-readiness:yes'
          label={`I'd like to start as soon as possible`}
          value={READY_TO_START}
        />
        <Radio
          test-id='radio:care-readiness:no'
          label={`I'm not sure, I'd like a little more information first`}
          value={NOT_READY}
        />
      </RadioGroup>
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Next
            </PrimaryButton>
            {onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Group position='apart'>
            {onBack && (
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            )}
            <Group position='right'>
              <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
