import { ApiSchemas, OpheliaApiDefinitionFromSchemas } from '../../utils'
import { MyAppointmentsApi } from './appointments'
import { MyCalendarHoldsApi } from './calendarHolds'
import { MyDropInClinicApi } from './dropInClinic'
import { MyInsuranceCoverageApi } from './insurance/insurance'
import { MyInsurancePayersApi } from './insurance/payers'
import { MyIntakeStatesApi } from './intakeStates'
import { MyAvailabilityApi } from './settings'
import { WorkflowPromptResponsesRouteSchemas } from './workflowPromptResponses'

export const MyLunaSchemas = {
  ...MyAvailabilityApi,
  ...MyAppointmentsApi,
  ...MyCalendarHoldsApi,
  ...MyInsuranceCoverageApi,
  ...MyInsurancePayersApi,
  ...MyDropInClinicApi,
  ...MyIntakeStatesApi,
  ...WorkflowPromptResponsesRouteSchemas,
} satisfies ApiSchemas

export type MyLunaApi = OpheliaApiDefinitionFromSchemas<typeof MyLunaSchemas>
