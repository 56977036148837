import { PromptResponseModel, PromptResponsePayload, WidgetType } from '@shared/types'
import React from 'react'
import * as Widgets from './widgets'

export type WidgetProps = {
  widgetType: WidgetType
  response: PromptResponsePayload | undefined
  onSubmit: (data: PromptResponseModel['payload'], args?: { onError?: () => void }) => void
  onBack: (() => void) | undefined
}

/*
 * A widget should return `null` when it's not active.
 * This prevents unnecessary gaps from existing between content blocks.
 */
const widgetMap = new Map<WidgetType, (props: WidgetProps) => JSX.Element | null>([
  ['payment_method', Widgets.PaymentMethod],
  ['enrollment_call_calendar', Widgets.WelcomeCallCalendar],
  ['reconnect_call_calendar', Widgets.ReconnectCallCalendar],
  ['other_resources', Widgets.OtherResources],
  ['how_did_you_hear_about_us', Widgets.HowDidYouHearAboutUs],
  ['provider_contact_form', Widgets.ReferringProviderContactForm],
  ['referral_contact_form', Widgets.ReferralContactForm],
  ['share_friend_referral_link', Widgets.CopyFriendReferralLink],
  ['referral_email_sent', Widgets.ReferralEmailSent],
  ['partner_contact_form', Widgets.PartnerContactForm],
  ['spring_health_partner_form', Widgets.SpringHealthContactForm],
  ['partner_with_patient_contact_form', Widgets.ReferringProviderWithPatientContactForm],
  ['partner_with_patient_confirmation', Widgets.ReferringProviderWithPatientConfirmation],
  ['provider_referral_sent', Widgets.PartnerReferralSent],
  ['type_of_referral', Widgets.TypeOfReferral],
  ['refer_someone', Widgets.ReferSomeone],
  ['not_ready_alternative_options', Widgets.NotReadyAlternativeOptionsWidget],
  ['not_ready_contact_info', Widgets.NotReadyContactForm],
  ['not_ready_confirmation', Widgets.NotReadyConfirmation],
  ['welcome_call_explanation', Widgets.WelcomeCallExplanation],
  ['motivations_for_treatment_form', Widgets.MotivationsForTreatmentForm],
  ['im_with_the_patient', Widgets.ImWithThePatient],
  ['who_referred_you', Widgets.WhoReferredYou],
  [
    'current_substances_and_prescribed_opioids_used',
    Widgets.CurrentSubstancesAndPrescribedOpioidsUsed,
  ],
  ['past_opioid_use_disorder_treatment', Widgets.PastOpioidUseDisorderTreatment],
  ['psych_diagnoses_history', Widgets.PsychDiagnosesHistory],
  ['correctional_facility_form', Widgets.CorrectionalFacilityForm],
  ['correctional_facility_intake_visit_schedule', Widgets.CorrectionalFacilityIntakeVisitSchedule],
  [
    'correctional_facility_intake_visit_confirmed',
    Widgets.CorrectionalFacilityIntakeVisitConfirmed,
  ],

  // v12 start
  ['how_to_get_started', Widgets.HowToGetStarted],
  /*
   * payment_method_intake_visit uses the normal PaymentMethod widget, but
   * it's a separate widget type so that we can branch properly
   */
  ['payment_method_intake_visit', Widgets.PaymentMethod],
  ['insurance_verification', Widgets.InsuranceVerification],
  ['schedule_intake_visit', Widgets.ScheduleIntakeVisit],
  ['schedule_welcome_call_care_transfer', Widgets.ScheduleWelcomeCallCareTransfer],
  ['suboxone_prescribed_by', Widgets.SuboxonePrescribedBy],
  ['reason_for_new_provider', Widgets.ReasonForNewProvider],
  ['social_determinants_of_health', Widgets.SocialDeterminantsOfHealthCareTransfer],
  // v13 start
  ['how_to_connect', Widgets.HowToConnect],
  ['care_readiness_chat', Widgets.CareReadinessChat],
  ['start_welcome_chat', Widgets.StartWelcomeChat],
])

export const PromptContentWidget = (props: WidgetProps) => {
  const Component = widgetMap.get(props.widgetType)

  if (Component) {
    return <Component {...props} />
  }

  return null
}
