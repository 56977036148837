import { template } from './template'

export const getConversationId = ({
  userId,
  appointmentId,
}: {
  userId?: string | undefined
  appointmentId?: string | number | undefined
}) =>
  template(`{userId}{appointmentId}`, {
    userId,
    appointmentId: appointmentId ? `__${appointmentId}` : '',
  })
