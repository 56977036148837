import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  PrimaryButton,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  TitleTwo,
  useLifecycle,
  validateWith,
} from '@shared/components'
import { PromptResponsePayload } from '@shared/types'
import { dayjs } from '@shared/utils'
import React from 'react'
import * as FullStory from '../../../../../src/common/fullstory'
import { isAtleastOneWord, isRequired } from '../../../forms'
import { useAuth } from '../../../hooks'
import { useCallMeNowQueueStatus } from '../../../hooks/use-call-me-now-queue-status'
import { sendPageEvent } from '../../../rudderstack'
import { getSessionStorageItem } from '../../../storage'
import { WidgetProps } from '../PromptContentWidget'

export const StartWelcomeChat = ({ ...props }: WidgetProps) => {
  const { currentUser } = useAuth()
  const { isQueueAvailable } = useCallMeNowQueueStatus()

  const { values, validate, getInputProps } = useForm({
    initialValues: {
      firstName:
        currentUser?.data?.personalData?.preferredName ||
        currentUser?.data?.personalData?.firstName ||
        '',
      lastName: currentUser?.data?.personalData?.lastName || '',
    },
    validate: {
      firstName: validateWith(isRequired, isAtleastOneWord),
      lastName: validateWith(isRequired, isAtleastOneWord),
    },
  })

  if (!isQueueAvailable) {
    // show call me now page instead, but with an explanation that there are no agents available
  }

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }

    const payload: PromptResponsePayload<'onboarding'> = {
      value: {
        contextKey: 'chat_now',
      },
      start_welcome_chat_type: {
        contextKey: 'chat_now',
      },
      start_welcome_chat_reconnect: {
        contextKey: 'custom',
        value: currentUser?.data?.statuses?.patient === 'discharged',
      },
      start_welcome_chat_first_name: {
        contextKey: 'custom',
        value: values.firstName,
      },
      start_welcome_chat_last_name: {
        contextKey: 'custom',
        value: values.lastName,
      },
      start_welcome_chat_timezone: {
        contextKey: 'custom',
        value: dayjs.tz.guess(),
      },
      start_welcome_chat_address_state: {
        contextKey: 'custom',
        // Reconnect has no residence_state
        value: getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state || '',
      },
    }

    props.onSubmit(payload)
  }

  useLifecycle({
    onMount: () => {
      sendPageEvent('Start Welcome Chat')
      FullStory.event('Start Welcome Chat CTA is visible')
    },
  })

  return (
    <Stack spacing='lg'>
      <Stack>
        <TitleTwo>Want to chat right now?</TitleTwo>
        <Text>Let us know your name to get started.</Text>
      </Stack>
      <Stack>
        <TextInput label='First name' placeholder='First name' {...getInputProps('firstName')} />
        <TextInput label='Last name' placeholder='Last name' {...getInputProps('lastName')} />
      </Stack>
      <Stack spacing='lg' justify='center'>
        <PrimaryButton fullWidth onClick={onSubmit}>
          Start a live chat
        </PrimaryButton>
        {props.onBack && (
          <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={props.onBack}>
            Back
          </TertiaryButton>
        )}
      </Stack>
    </Stack>
  )
}
