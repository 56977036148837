import {
  ArrowLeftIcon,
  PrimaryButton,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import React from 'react'
import * as FullStory from '../../../../common/fullstory'
import { useMyMutation, useOnboardingDims } from '../../../../common/hooks'
import { AppointmentInfo, ContentToDisplay } from './RescheduleWelcomeCall'

type RequestCallMeNowProps = {
  appointmentInfo: AppointmentInfo
  updateContentToDisplay: (contentToDisplay: ContentToDisplay) => void
}

// This code is effectively copy+pasted from enterCallMeNow/index.tsx
export const RequestCallMeNow = ({
  appointmentInfo,
  updateContentToDisplay,
}: RequestCallMeNowProps) => {
  const { showBanner } = useBanner()
  const { isMobile } = useOnboardingDims()

  useLifecycle({
    onMount: () => {
      FullStory.event('[Welcome Call Experiment] Clicked CMN Banner')
    },
  })

  /**
   * If appointment is happening now, we want to show the message
   * that we will call them in 20-30 minutes, so we will delay showing
   * the expired message until 15 min past the appointment time.
   */
  const isHappeningNow = appointmentInfo?.status === 'happening-now'

  const isCanceled =
    appointmentInfo?.status === 'canceled' ||
    appointmentInfo?.status === 'no-show' ||
    appointmentInfo?.status === 'late-cancellation'

  const isInPast =
    dayjs(appointmentInfo?.datetime).isBefore(dayjs().add(30, 'minutes')) && !isHappeningNow

  const isExpired = isCanceled || isInPast

  const createOrUpdateAppointment = useMyMutation(
    isExpired ? 'POST /appointments/:id/call-me-now' : 'PUT /appointments/:id/call-me-now',
  )

  const handleClick = () => {
    if (!appointmentInfo.id) {
      return
    }
    createOrUpdateAppointment.mutate(
      {
        params: {
          id: `${appointmentInfo.id}`,
        },
      },
      {
        onSuccess: () => {
          updateContentToDisplay('cmn_successfully_requested')
        },
        onError: () => {
          showBanner({
            type: 'error',
            label: 'Failed to request earlier appointment. Please try again.',
          })
        },
      },
    )
  }

  return (
    <Stack spacing='md'>
      <TitleTwo>Have time to talk right now?</TitleTwo>
      <Text>We&apos;ll give you a call within the next 30 minutes.</Text>
      <PrimaryButton
        onClick={handleClick}
        fullWidth={isMobile}
        loading={createOrUpdateAppointment.isLoading}
        disabled={createOrUpdateAppointment.isLoading}
      >
        Request a call now
      </PrimaryButton>
      <TertiaryButton
        leftIcon={<ArrowLeftIcon />}
        onClick={() => updateContentToDisplay('reschedule_appointment')}
        fullWidth={isMobile}
      >
        Back
      </TertiaryButton>
    </Stack>
  )
}
