export function zoom(location: string | undefined) {
  if (!location) {
    return {
      zoomUrl: '',
      meetingCanceled: true,
    }
  }

  const zoomUrl = location.match(/\bhttps?:\/\/\S+/)?.[0] ?? ''

  return {
    zoomUrl,
    meetingCanceled: !zoomUrl,
  }
}

export const isLocationURLPortalRedirect = (location: string | undefined) =>
  location?.match(/\bhttps:\/\/my\.\S+/)?.[0]
