import { z } from 'zod'
import { ApiSchemas } from '../../utils'

const WORKFLOW_PROMPT_RESPONSE_VALUES = ['who_referred_you'] as const

const PromptResponseValueSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.array(z.string()),
  z.undefined(),
])

const GetWorkflowPromptResponsesWhoReferredYouReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
      value: z.enum(WORKFLOW_PROMPT_RESPONSE_VALUES),
    }),
  }),
  res: z.object({
    data: z.record(z.string(), PromptResponseValueSchema).nullable(),
  }),
}

export const WorkflowPromptResponsesRouteSchemas = {
  'GET /workflow-prompt-responses/:patientId/value/:value':
    GetWorkflowPromptResponsesWhoReferredYouReqRes,
} satisfies ApiSchemas
