import { isEmail, useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  PhoneInput,
  Select,
  Stack,
  TextInput,
  TitleOne,
  TitleTwo,
  isAllSelected,
  validateWith,
} from '@shared/components'
import { states } from '@shared/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { isAtleastOneWord, isInactiveState, isPhone, isRequired } from '../../../forms'
import { useReferralPartner } from '../../../hooks/use-partner'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

enum AcknowledgementValues {
  Email = 'email',
}

enum IsBilledBySpringHealth {
  IsBilledBySpringHealth = 'true',
}

type ReferralContactFormType = {
  springHealthId: string
  isBilledBySpringHealth: string[]
  referringProviderEmail: string
  email: string
  state: string
  firstName: string
  phone: string
  acknowledgement: string[]
}

export const SpringHealthContactForm = ({ onSubmit }: WidgetProps) => {
  const { partner } = useReferralPartner()

  const navigate = useNavigate()
  const onBack = () => navigate(-1)

  const form = useForm<ReferralContactFormType>({
    validateInputOnBlur: [
      'firstName',
      'email',
      'referringProviderEmail',
      'phone',
      'state',
      'springHealthId',
    ],
    initialValues: {
      springHealthId: '',
      isBilledBySpringHealth: [],
      referringProviderEmail: '',
      email: '',
      firstName: '',
      phone: '',
      state: '',
      acknowledgement: [],
    },
    validate: {
      firstName: validateWith(isRequired, isAtleastOneWord),
      referringProviderEmail: validateWith(isRequired, isEmail),
      email: validateWith(isRequired, isEmail),
      phone: validateWith(isRequired, isPhone),
      state: validateWith(isRequired, isInactiveState),
      acknowledgement: isAllSelected(Object.values(AcknowledgementValues), 'Required'),
      springHealthId: validateWith(isRequired),
    },
  })

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    if (!partner) {
      return
    }

    onSubmit({
      value: {
        contextKey: 'custom',
      },
      provider_email: {
        contextKey: 'custom',
        value: form.values.referringProviderEmail,
      },
      provider_organization: {
        contextKey: 'custom',
        value: partner.name,
      },
      referral_email: {
        contextKey: 'custom',
        value: form.values.email,
      },
      referral_first_name: {
        contextKey: 'custom',
        value: form.values.firstName,
      },
      referral_phone: {
        contextKey: 'custom',
        value: form.values.phone,
      },
      referral_state: {
        contextKey: 'custom',
        value: form.values.state,
      },
      email_acknowledgement: {
        contextKey: 'custom',
        value: true,
      },
      spring_health_id: {
        contextKey: 'custom',
        value: form.values.springHealthId,
      },
      is_billed_by_spring_health: {
        contextKey: 'custom',
        value: form.values.isBilledBySpringHealth.includes(
          IsBilledBySpringHealth.IsBilledBySpringHealth,
        ),
      },
    })
  }

  return (
    <Stack spacing='md'>
      <TitleOne>Spring Health</TitleOne>
      <TitleTwo>
        Share your work email and your referral’s email and we’ll send them a link to get started
      </TitleTwo>
      <TextInput
        label='Your work email'
        placeholder='Your work email'
        {...form.getInputProps('referringProviderEmail')}
      />
      <TextInput
        label='Referral’s email'
        placeholder='Referral’s email'
        {...form.getInputProps('email')}
      />
      <TextInput
        label='Referral’s first name'
        placeholder='Referral’s first name'
        {...form.getInputProps('firstName')}
      />
      <PhoneInput
        label='Referral’s phone number'
        {...form.getInputProps('phone')}
        explanation='We’ll only use this number to call your referral if they don’t respond to our email outreach'
      />
      <Select
        clearable
        placeholder='Select one...'
        label='Referral’s state of residence'
        data={[
          ...states
            .filter(state => state.active)
            .map(state => ({
              value: state.abbr,
              label: state.state,
            })),
          { value: 'other', label: 'Other' },
        ]}
        {...form.getInputProps('state')}
      />
      <TextInput
        label="Referral's Spring Health ID"
        placeholder="Referral's Spring Health ID"
        {...form.getInputProps('springHealthId')}
      />
      <CheckboxGroup {...form.getInputProps('acknowledgement')}>
        <Checkbox
          value={AcknowledgementValues.Email}
          label='I acknowledge that Ophelia will reach out to the patient and mention that I referred them'
        />
      </CheckboxGroup>
      <CheckboxGroup {...form.getInputProps('isBilledBySpringHealth')}>
        <Checkbox
          value={IsBilledBySpringHealth.IsBilledBySpringHealth}
          label='Patient is billed by Spring Health'
        />
      </CheckboxGroup>
      <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
    </Stack>
  )
}
