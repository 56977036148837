import { useForm } from '@mantine/form'
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Stack,
  Text,
  TitleTwo,
  isAnySelected,
} from '@shared/components'
import { PromptResponseContextKey, ValueOf } from '@shared/types'
import React from 'react'
import * as FullStory from '../../../fullstory'
import { useAuth } from '../../../hooks'
import { useCareTransferAvailability } from '../../../hooks/use-care-transfer-availability'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

type SocialDeterminantsOfHealthForm = {
  options: PromptResponseContextKey[]
}

const socialDeterminantsOfHealthOptions = {
  stable_housing_access: 'stable_housing_access',
  paying_bills: 'paying_bills',
  reliable_transportation: 'reliable_transportation',
  abuse_or_violence_in_relationships: 'abuse_or_violence_in_relationships',
  criminal_justice_involvement: 'criminal_justice_involvement',
  safety_concerns: 'safety_concerns',
  social_determinants_none: 'social_determinants_none',
}

export type SocialDeterminantsOfHealthOptions = ValueOf<typeof socialDeterminantsOfHealthOptions>

export const SocialDeterminantsOfHealthCareTransfer = ({
  response,
  onSubmit,
  onBack,
}: WidgetProps) => {
  const { currentUser } = useAuth()
  const { isCareTransferAvailable } = useCareTransferAvailability()
  const socialDeterminantsOfHealthForm = useForm<SocialDeterminantsOfHealthForm>({
    initialValues: {
      options: (response?.social_determinants_of_health?.contextKey ??
        []) as PromptResponseContextKey[],
    },
    validate: {
      options: isAnySelected(Object.values(socialDeterminantsOfHealthOptions), 'Required'),
    },
  })

  const disableAllOtherOptions = () =>
    socialDeterminantsOfHealthForm.values.options.includes('social_determinants_none')

  const submitHandler = () => {
    const { hasErrors } = socialDeterminantsOfHealthForm.validate()

    if (hasErrors) {
      return
    }

    const answersArray = socialDeterminantsOfHealthForm.values.options.filter(option => {
      if (socialDeterminantsOfHealthForm.values.options.includes('social_determinants_none')) {
        return option === 'social_determinants_none'
      }
      return true
    })

    const shouldBranchToCareTransfer =
      currentUser?.data?.experiments?.['care-transfer-cohort-split']?.cohort === 'test' &&
      isCareTransferAvailable

    if (shouldBranchToCareTransfer) {
      FullStory.event('Patient Is In Care Transfer Test Cohort')
    }

    onSubmit({
      value: {
        contextKey: shouldBranchToCareTransfer
          ? 'social_determinants_of_health_care_transfer'
          : 'social_determinants_of_health',
      },
      social_determinants_of_health: {
        value: 'custom',
        contextKey: answersArray,
      },
    })
  }

  return (
    <Stack spacing='lg'>
      <Stack spacing='xs'>
        <TitleTwo>
          In the past two months, have you had difficulty with any of the following?
        </TitleTwo>
        <Text>
          We want to help you with any difficulties you&apos;re facing. Please check all that apply.
        </Text>
      </Stack>
      <Stack spacing='md'>
        <Stack spacing='md'>
          <Box test-id='content:multiple-choice'>
            <CheckboxGroup {...socialDeterminantsOfHealthForm.getInputProps('options')}>
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={socialDeterminantsOfHealthOptions.stable_housing_access}
                label='Difficulty accessing stable housing'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={socialDeterminantsOfHealthOptions.paying_bills}
                label='Difficulty paying for basic living essentials (such as food or utilities)'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={socialDeterminantsOfHealthOptions.reliable_transportation}
                label='Difficulty accessing reliable transportation (including gas costs)'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={socialDeterminantsOfHealthOptions.abuse_or_violence_in_relationships}
                label='Difficulty with abusive or violent relationships with family or friends'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={socialDeterminantsOfHealthOptions.criminal_justice_involvement}
                label='Difficulty with criminal justice involvement (such as incarceration or probation)'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={socialDeterminantsOfHealthOptions.safety_concerns}
                label='Concerns about safety in your neighborhood'
              />
              <Checkbox
                value={socialDeterminantsOfHealthOptions.social_determinants_none}
                label='None of the above'
              />
            </CheckboxGroup>
          </Box>
        </Stack>
        <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
      </Stack>
    </Stack>
  )
}
