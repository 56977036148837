import { z } from 'zod'
import { ApiSchemas } from '../../utils'

const GetCampaignsAccountReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      id: z.string(),
      subscribed: z.boolean(),
    }),
  }),
}

const UpdateSubscriptionStatusReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
    body: z.object({
      subscribed: z.boolean(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const CampaignsRouteSchemas = {
  'GET /patients/:patientId/campaigns/account': GetCampaignsAccountReqRes,
  'PUT /patients/:patientId/campaigns/subscribe': UpdateSubscriptionStatusReqRes,
} satisfies ApiSchemas
