import {
  ArrowLeftIcon,
  Stack,
  TertiaryButton,
  TitleTwo,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { ISOString } from '@shared/types'
import { dayjs, template } from '@shared/utils'
import React from 'react'
import * as FullStory from '../../../../common/fullstory'
import { useOnboardingDims } from '../../../../common/hooks'
import { ContentToDisplay } from './RescheduleWelcomeCall'

type RescheduledSuccessfullyProps = {
  // @todo this should be required
  updatedAppointmentDatetime?: ISOString | undefined
  updateContentToDisplay: (contentToDisplay: ContentToDisplay) => void
}

export const RescheduledSuccessfully = ({
  updatedAppointmentDatetime,
  updateContentToDisplay,
}: RescheduledSuccessfullyProps) => {
  const { showBanner } = useBanner()
  const { isMobile } = useOnboardingDims()

  useLifecycle({
    onMount: () => {
      FullStory.event('[Welcome Call Experiment] Rescheduled Appointment')

      showBanner({
        type: 'success',
        label: 'Welcome call confirmed',
      })
    },
  })

  /*
   * @todo fix the type. This component should never get rendered
   * without an updatedAppointmentDatetime, but this appeases the
   * type checker
   */
  if (!updatedAppointmentDatetime) {
    return null
  }

  const successMessage = template(`We'll give you a call at {time} on {date}`, {
    time: dayjs(updatedAppointmentDatetime).format('h:mma z'),
    date: dayjs(updatedAppointmentDatetime).format('MM/DD/YY'),
  })

  return (
    <Stack>
      <TitleTwo>{successMessage}</TitleTwo>
      <TertiaryButton
        leftIcon={<ArrowLeftIcon />}
        onClick={() => updateContentToDisplay('reschedule_appointment')}
        fullWidth={isMobile}
      >
        Back
      </TertiaryButton>
    </Stack>
  )
}
