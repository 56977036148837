import { UserSession } from '@shared/types'
import { dayjs, isFulfilled } from '@shared/utils'
import { v4 as publicIpv4 } from 'public-ip'
import { logger } from './logger'
import { analytics } from './rudderstack'

export const retrieveIpAddress = () => {
  try {
    return publicIpv4()
  } catch (error) {
    logger.warn('Failed to retrieve public IP address')
    throw error
  }
}

export const getSessionData = async (): Promise<Partial<UserSession>> => {
  const userSession: Partial<UserSession> = {
    startedAt: dayjs().toISOString(),
  }

  const [ipAddress] = await Promise.allSettled([retrieveIpAddress()])

  if (isFulfilled(ipAddress)) {
    userSession.ipAddress = ipAddress.value
  }

  const anonymousId = analytics.getAnonymousId()

  if (anonymousId) {
    userSession.anonymousId = anonymousId
  }

  const { userAgent } = window.navigator

  if (userAgent) {
    userSession.userAgent = userAgent
  }

  return userSession
}
