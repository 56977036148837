// eslint-disable-next-line no-restricted-imports
import { Collapse, SpacingValue, SystemProp } from '@mantine/core'
import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react'
import { Banner, BannerType } from '../banners'

type Banner = {
  type: BannerType
  label: string
  icon?: ReactNode
  bg?: string
  dismissable?: boolean
  persistAfterNavigation?: boolean
  description?: string
}

type Context = {
  banner: Banner | undefined
  bannerVisible: boolean
  showBanner: (arg: Banner) => void
  hideBanner: () => void
  cleanBanner: () => void
}

const initialContext: Context = {
  banner: undefined,
  bannerVisible: false,
  showBanner: () => void 0,
  hideBanner: () => void 0,
  cleanBanner: () => void 0,
}

const BannerContext = createContext<Context>(initialContext)

export const BannerProvider = ({ children }: { children: React.ReactNode }) => {
  const [banner, setBanner] = useState<Banner | undefined>(undefined)
  const [visible, setVisible] = useState(false)

  const context: Context = {
    banner,
    bannerVisible: visible,
    cleanBanner: useCallback(() => {
      setBanner(undefined)
      setVisible(false)
    }, []),
    hideBanner: useCallback(() => setVisible(false), []),
    showBanner: useCallback((banner: Banner) => {
      setBanner(banner)
      setVisible(true)
    }, []),
  }

  return <BannerContext.Provider value={context}>{children}</BannerContext.Provider>
}

export const useBanner = () => {
  return useContext(BannerContext)
}

export const BannerPortal = (props: { mb?: SystemProp<SpacingValue> }) => {
  const { banner, bannerVisible, hideBanner } = useBanner()

  return (
    <Collapse
      in={bannerVisible}
      style={banner?.type === 'error' ? { position: 'sticky', top: 0, zIndex: 1 } : {}}
    >
      {banner?.type ? (
        <Banner
          {...props}
          {...(banner.bg && { bg: banner.bg })}
          type={banner?.type ?? 'success'}
          label={banner?.label ?? ''}
          onClick={banner?.dismissable ? hideBanner : undefined}
          description={banner?.description ?? ''}
          className='no-print'
        />
      ) : null}
    </Collapse>
  )
}
