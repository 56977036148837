import { YYYYMMDD } from '../dates'

type EnrollmentPanelRow = {
  enrollmentCoordinatorId: string | null
  enrollmentCoordinatorName: string | null
  patientId: string | null
  patientName: string | null
  patientPhoneNumber: string | null
  patientStatus: string | null
  patientState: string | null
  patientInsurancePlanName: string | null
  patientBillingOption: string | null
  patientLastWelcomeCallDate: string | null
  patientContactCount: number | null
  patientLastWinbackCallDate: string | null
  priority: 5 | 4 | 3 | 2 | 1 | null
  claimed?: boolean
}

type ReferralLeadRow = {
  patient_id: string | null
  patientName: string | null
  patient_status: string | null
  current_patient_state: string | null
  insurance_provider: string | null
  current_billing_option: string | null
  contacts: number | null
  last_winback_Call: string | null
  priority: 5 | 4 | 3 | 2 | 1 | null
  referral_source: string | null
  enrollment_coordinator_employee_id: string | null
  claimed?: boolean
}

type RudderstackWwwSessionsRow = {
  session_id: number
  pageviews_count: number
  pages_viewed: string
  min_time: string
  max_time: string
  session_time_mins: number
  start_url: string
  anonymous_id: string
  landing_page: string
  gclid: string | null
  rdt_cid: string | null
  ttclid: string | null
  msclkid: string | null
  utm_source: string
  utm_campaign: string
  utm_medium: string
  user_agent: string
  visited_my_ophelia: boolean | null
  opt_out_value: boolean | null
}

type CredentialedProviderRow = {
  provider_id: string
  eligible_id: string
}

type PayerIdPatientCountsRow = {
  eligiblePayerId: string
  patientCount: number
}

type UnpaidPastDueInvoicesRow = {
  patient_id: string
  invoice_id: string
}

export type ForecastedCapacityMinusHoldsRow = {
  employeeId: string
  numMinutesForecastedCapacityMinusHolds: number
}

export type FollowUpForecastModelInputsRow = {
  employee_id: string | null
  cancelation_rate: number | null
  late_cancelation_rate: number | null
  month_of_year: number | null

  dfum_lag1: number | null
  dfum_lag2: number | null
  dfum_lag3: number | null
  dfum_lag4: number | null
  dfum_lag5: number | null
  dfum_lag6: number | null
  dfum_lag7: number | null
  dfum_lag8: number | null

  aiv_lag1: number | null
  aiv_lag2: number | null
  aiv_lag3: number | null
  aiv_lag4: number | null
  aiv_lag5: number | null
  aiv_lag6: number | null
  aiv_lag7: number | null
  aiv_lag8: number | null

  sfum_1: number | null
  sfum_2: number | null
  sfum_3: number | null
  sfum_4: number | null
  sfum_5: number | null
  sfum_6: number | null

  siv_1: number | null
  siv_2: number | null
  siv_3: number | null
  siv_4: number | null
  siv_5: number | null
  siv_6: number | null

  ooo_lag1: number | null
  ooo_lag2: number | null
  ooo_lag3: number | null
  ooo_lag4: number | null
  ooo_lag5: number | null
  ooo_lag6: number | null
  ooo_lag7: number | null
  ooo_lag8: number | null

  ooo_1: number | null
  ooo_2: number | null
  ooo_3: number | null
  ooo_4: number | null
  ooo_5: number | null
  ooo_6: number | null
}

export type BigQueryRowTypes = {
  referralLeads: ReferralLeadRow
  enrollmentPanel: EnrollmentPanelRow
  unpaidPastDueInvoices: UnpaidPastDueInvoicesRow
  rudderstackWwwSessions: RudderstackWwwSessionsRow
  credentialedProviders: CredentialedProviderRow
  payerIdPatientCounts: PayerIdPatientCountsRow
  forecastedCapacityMinusHolds: ForecastedCapacityMinusHoldsRow
  followUpForecastModelInputs: FollowUpForecastModelInputsRow
}

export type BigQueryKey = keyof BigQueryRowTypes

export type GetReverseEtlDataProps<Key extends BigQueryKey = BigQueryKey> =
  Key extends 'forecastedCapacityMinusHolds'
    ? {
        queryKey: Key
        params: {
          startDate: YYYYMMDD
          endDate: YYYYMMDD
        }
      }
    : Key extends 'followUpForecastModelInputs'
    ? {
        queryKey: Key
        params?: undefined
      }
    : { queryKey: Key; params?: undefined }

export const BIGQUERY_QUERY_STRINGS: Record<BigQueryKey, string> = {
  followUpForecastModelInputs: `
  WITH
      -- Step 1: Get employee_id, names, calendar_ids
      prescribing_clinicians AS (
          SELECT DISTINCT prescribing_clinician_id as employee_id,
          e.calendar_id
          FROM \`ophelia-data-marts.ophelia_core.patients\` p
          JOIN  \`ophelia-data-marts.ophelia_core.employees\` e
          ON prescribing_clinician_id = e.employee_id
          -- You want to keep this to get more data.
          -- The model starts to overfit otherwise.
          -- WHERE patient_status = 'in treatment'
      ),

      -- Generate index dates aligned to Mondays
      index_date AS (
          SELECT
              index_date
          FROM
              UNNEST(
                  GENERATE_DATE_ARRAY(
                      DATE_TRUNC(DATE('2022-01-01'), WEEK(MONDAY)),
                      DATE_TRUNC(CURRENT_DATE(), WEEK(MONDAY)),
                      INTERVAL 1 WEEK
                  )
              ) AS index_date
      ),
      -- Step 5: Collect columns from appointments table
      appointments AS (
          SELECT
              pc.employee_id,
              DATE_TRUNC(DATE(appointment_at_utc), WEEK(MONDAY)) AS calendar_week,
              IF(
                  appointment_type_grouping = 'Initial Visit',
                  'Initial Visit',
                  'Follow-Up Visit'
              ) AS visit_type,
              scheduled_duration_minutes AS duration,
              COALESCE(TIMESTAMP_DIFF(appointment_at_utc, canceled_at_utc, MINUTE) <= 1440, FALSE) AS is_late_canceled,
              is_canceled AND (canceled_at_utc IS NULL) AS is_unknown_cancelation,
              is_canceled,
              was_attended,
              appointment_at_utc,
              created_at_utc,
              canceled_at_utc,
          FROM \`ophelia-data-marts.ophelia_core.appointments\` a
          JOIN prescribing_clinicians pc ON pc.calendar_id = a.calendar_id
      ),

      -- Step 6: Collect columns from holds
      calendar_holds AS (
          SELECT
              pc.employee_id,
              DATE_TRUNC(DATE(ch.intended_day), WEEK(MONDAY)) AS calendar_week,
              'Follow-Up Visit' AS visit_type,
              duration,
              FALSE AS is_late_canceled,
              FALSE AS is_unknown_cancelation,
              FALSE AS is_canceled,
              FALSE AS was_attended,
              TIMESTAMP(NULL) AS appointment_at_utc,
              ch.created_at_utc,
              TIMESTAMP(NULL) AS canceled_at_utc,
          FROM \`ophelia-data-marts.ophelia_core.calendar_holds\` ch
          JOIN prescribing_clinicians pc ON pc.calendar_id = ch.calendar_id
          WHERE ch.appointment_id IS NULL
      ),

      -- Step 9: Collect from blocks and include created_at_utc
      blocks AS (
          SELECT
              pc.employee_id,
              DATE_TRUNC(cb.start_date, WEEK(MONDAY)) AS calendar_week,
              'Block' AS visit_type,
              cb.duration_minutes AS duration,
              FALSE AS is_late_canceled,
              FALSE AS is_unknown_cancelation,
              FALSE AS is_canceled,
              FALSE AS was_attended,
              cb.start_datetime_at_utc AS appointment_at_utc,
              TIMESTAMP(NULL) AS created_at_utc,
              TIMESTAMP(NULL) AS canceled_at_utc,
          FROM \`ophelia-data-marts.ophelia_core_intermediate.int_calendar_blocks\` cb
          JOIN prescribing_clinicians pc ON pc.calendar_id = cb.calendar_id
      ),

      -- Step 10: Union appointments, holds, and blocks
      appointments_holds_blocks AS (
          SELECT *
          FROM appointments
          UNION ALL
          SELECT *
          FROM calendar_holds
          UNION ALL
          SELECT *
          FROM blocks
      ),

      -- Step 11: Relate an index against all blocks, holds, and appointments
      index_relational AS (
          SELECT
              id.index_date,
              employee_id,
              visit_type,
              calendar_week,
              DATE_DIFF(calendar_week, id.index_date, WEEK(MONDAY)) AS week_number,
              -- For blocks w/o created_at_utc
              COALESCE(DATE(ahb.created_at_utc) < id.index_date, FALSE) AS is_created_before_index,
              -- For any w/o canceled_at_utc
              COALESCE(DATE(ahb.canceled_at_utc) > id.index_date, FALSE) AS is_canceled_after_index,
              COALESCE(DATE(ahb.canceled_at_utc) < id.index_date, FALSE) AS is_canceled_before_index,
              COALESCE(is_late_canceled, FALSE) AS is_late_canceled,
              COALESCE(is_canceled, FALSE) AS is_canceled,
              COALESCE(was_attended, FALSE) AS was_attended,
              COALESCE(duration, 0) AS duration
          FROM
              index_date id
              CROSS JOIN appointments_holds_blocks ahb
          WHERE
              calendar_week BETWEEN DATE_SUB(id.index_date, INTERVAL 9 WEEK) AND DATE_ADD(id.index_date, INTERVAL 9 WEEK)
      ),

      -- Compute measures before pivoting
      computed_measures AS (
          SELECT
              ir.index_date,
              ir.employee_id,
              ir.week_number,
              CASE
                  WHEN (was_attended AND visit_type = 'Initial Visit')
                  THEN 1
                  ELSE 0
              END AS aiv,
              CASE
                  WHEN visit_type = 'Initial Visit'
                      AND is_created_before_index
                      AND (NOT is_canceled_before_index OR is_canceled_after_index)
                  THEN 1
                  ELSE 0
              END AS siv,
              CASE
                  WHEN visit_type = 'Follow-Up Visit'
                      AND is_created_before_index
                      AND (NOT is_canceled_before_index OR is_canceled_after_index)
                  THEN duration
                  ELSE 0
              END AS sfum,
              CASE
                  WHEN (was_attended OR is_late_canceled)
                      AND visit_type = 'Follow-Up Visit'
                  THEN duration
                  ELSE 0
              END AS dfum,
              CASE
                  WHEN visit_type = 'Block'
                  THEN duration
                  ELSE 0
              END AS ooo,
              CASE WHEN is_canceled THEN 1 ELSE 0 END AS is_canceled_int,
              CASE WHEN is_late_canceled THEN 1 ELSE 0 END AS is_late_canceled_int
          FROM index_relational ir
      ),

      pivot_by_week AS (
          SELECT
              index_date,
              employee_id,
              EXTRACT(MONTH FROM index_date) AS month_of_year,

              CAST(COALESCE(dfum_1, 0) AS FLOAT64) AS dfum_1,
              CAST(COALESCE(dfum_2, 0) AS FLOAT64) AS dfum_2,
              CAST(COALESCE(dfum_3, 0) AS FLOAT64) AS dfum_3,
              CAST(COALESCE(dfum_4, 0) AS FLOAT64) AS dfum_4,
              CAST(COALESCE(dfum_5, 0) AS FLOAT64) AS dfum_5,
              CAST(COALESCE(dfum_6, 0) AS FLOAT64) AS dfum_6,
              CAST(COALESCE(dfum_7, 0) AS FLOAT64) AS dfum_7,
              CAST(COALESCE(dfum_8, 0) AS FLOAT64) AS dfum_8,

              CAST(dfum_lag1 AS FLOAT64) AS dfum_lag1,
              CAST(dfum_lag2 AS FLOAT64) AS dfum_lag2,
              CAST(dfum_lag3 AS FLOAT64) AS dfum_lag3,
              CAST(dfum_lag4 AS FLOAT64) AS dfum_lag4,
              CAST(dfum_lag5 AS FLOAT64) AS dfum_lag5,
              CAST(dfum_lag6 AS FLOAT64) AS dfum_lag6,
              CAST(dfum_lag7 AS FLOAT64) AS dfum_lag7,
              CAST(dfum_lag8 AS FLOAT64) AS dfum_lag8,

              CAST(aiv_lag1 AS INT64) AS aiv_lag1,
              CAST(aiv_lag2 AS INT64) AS aiv_lag2,
              CAST(aiv_lag3 AS INT64) AS aiv_lag3,
              CAST(aiv_lag4 AS INT64) AS aiv_lag4,
              CAST(aiv_lag5 AS INT64) AS aiv_lag5,
              CAST(aiv_lag6 AS INT64) AS aiv_lag6,
              CAST(aiv_lag7 AS INT64) AS aiv_lag7,
              CAST(aiv_lag8 AS INT64) AS aiv_lag8,

              CAST(sfum_1 AS FLOAT64) AS sfum_1,
              CAST(sfum_2 AS FLOAT64) AS sfum_2,
              CAST(sfum_3 AS FLOAT64) AS sfum_3,
              CAST(sfum_4 AS FLOAT64) AS sfum_4,
              CAST(sfum_5 AS FLOAT64) AS sfum_5,
              CAST(sfum_6 AS FLOAT64) AS sfum_6,

              CAST(siv_1 AS INT64) AS siv_1,
              CAST(siv_2 AS INT64) AS siv_2,
              CAST(siv_3 AS INT64) AS siv_3,
              CAST(siv_4 AS INT64) AS siv_4,
              CAST(siv_5 AS INT64) AS siv_5,
              CAST(siv_6 AS INT64) AS siv_6,

              CAST(ooo_lag1 AS FLOAT64) AS ooo_lag1,
              CAST(ooo_lag2 AS FLOAT64) AS ooo_lag2,
              CAST(ooo_lag3 AS FLOAT64) AS ooo_lag3,
              CAST(ooo_lag4 AS FLOAT64) AS ooo_lag4,
              CAST(ooo_lag5 AS FLOAT64) AS ooo_lag5,
              CAST(ooo_lag6 AS FLOAT64) AS ooo_lag6,
              CAST(ooo_lag7 AS FLOAT64) AS ooo_lag7,
              CAST(ooo_lag8 AS FLOAT64) AS ooo_lag8,

              CAST(ooo_1 AS FLOAT64) AS ooo_1,
              CAST(ooo_2 AS FLOAT64) AS ooo_2,
              CAST(ooo_3 AS FLOAT64) AS ooo_3,
              CAST(ooo_4 AS FLOAT64) AS ooo_4,
              CAST(ooo_5 AS FLOAT64) AS ooo_5,
              CAST(ooo_6 AS FLOAT64) AS ooo_6,

              CAST((
                  cancelation_rate_lag1
                  + cancelation_rate_lag2
                  + cancelation_rate_lag3
                  + cancelation_rate_lag4
                  + cancelation_rate_lag5
                  + cancelation_rate_lag6
              ) / 6 AS FLOAT64) AS cancelation_rate,

              CAST((
                  late_cancelation_rate_lag1
                  + late_cancelation_rate_lag2
                  + late_cancelation_rate_lag3
                  + late_cancelation_rate_lag4
                  + late_cancelation_rate_lag5
                  + late_cancelation_rate_lag6
              ) / 6 AS FLOAT64) AS late_cancelation_rate

          FROM computed_measures
          PIVOT (
              SUM(aiv) AS aiv,
              SUM(siv) AS siv,
              SUM(sfum) AS sfum,
              SUM(dfum) AS dfum,
              SUM(ooo) AS ooo,
              AVG(is_canceled_int) AS cancelation_rate,
              AVG(is_late_canceled_int) AS late_cancelation_rate
              -- week_number 0 represents the current week relative to the index_date.
              -- In other words, it includes data from the same week we're making predictions for.
              -- We train on complete weeks, so the code to update calendars should be run at the end of a complete week (monday morning)
              FOR week_number IN (
                  -8 AS lag8,
                  -7 AS lag7,
                  -6 AS lag6,
                  -5 AS lag5,
                  -4 AS lag4,
                  -3 AS lag3,
                  -2 AS lag2,
                  -1 AS lag1,
                  0 AS \`1\`,
                  1 AS \`2\`,
                  2 AS \`3\`,
                  3 AS \`4\`,
                  4 AS \`5\`,
                  5 AS \`6\`,
                  6 AS \`7\`,
                  7 AS \`8\`
              )
          )
      )
  SELECT * EXCEPT(index_date)
  FROM pivot_by_week
  -- Serve results for this week.
  WHERE DATE_DIFF(CURRENT_DATE(), index_date, WEEK(MONDAY)) = 0
  `,
  referralLeads: `
  WITH
  referrals AS (
  SELECT
    referral_patient_id,
    referral_created_at_utc,
    referral_source
  FROM
   ophelia-data-marts.ophelia_core.friend_referrals
  UNION ALL
  SELECT
    patient_id AS referral_patient_id,
    referral_created_at_utc,
    'provider' AS referral_source
  FROM
    ophelia-data-marts.ophelia_core.provider_referrals ),
  count_issues AS (
  SELECT
    patient_id,
    IFNULL(COUNT(*), 0) AS calls
  FROM
    ophelia-data-marts.ophelia_core.emr_issues
  WHERE
    issue_type = 'winback_call'
  GROUP BY
    patient_id ),
  last_issue AS (
  SELECT
    patient_id,
    CAST(created_at_utc AS date) AS last_winback_call,
    row_number () OVER (PARTITION BY patient_id ORDER BY created_at_utc DESC)
  FROM
    ophelia-data-marts.ophelia_core.emr_issues
  WHERE
    issue_type = 'winback_call' QUALIFY row_number () OVER (PARTITION BY patient_id ORDER BY created_at_utc DESC) = 1 ),
  winbacks AS (
  SELECT
    c.patient_id,
    c.calls,
    l.last_winback_call
  FROM
    count_issues c
  JOIN
    last_issue l
  ON
    c.patient_id = l.patient_id )
SELECT
 i.enrollment_coordinator,
 i.enrollment_coordinator_employee_id,
  p.patient_id,
  CONCAT(p.last_name, ', ', p.first_name) AS patientName,
  p.current_patient_state,
  k.first_lead_date_et,
  CAST(r.referral_created_at_utc AS date) AS Referral_Created_Date,
  r.referral_source,
  p.patient_status,
  p.current_billing_option,
  p.insurance_provider,
  p.insurance_plan_rollup_name,
  w.calls AS contacts,
  w.last_winback_Call
FROM
  ophelia-data-marts.ophelia_core.patients p
  join ophelia-data-marts.ophelia_core_intermediate.int_patients i
  on p.patient_id = i.patient_id
JOIN
  referrals r
ON
  p.patient_id = r.referral_patient_id
JOIN
  ophelia-data-marts.ophelia_core.patient_key_dates k
ON
  p.patient_id = k.patient_id
LEFT JOIN
  winbacks w
ON
  p.patient_id = w.patient_id
WHERE
  p.patient_status = 'lead'
  AND p.patient_id NOT IN (
  SELECT
    patient_id
  FROM
    ophelia-data-marts.ophelia_core.appointments
  WHERE
    appointment_type = 'Free Consultation Call'
    AND NOT is_canceled
    AND CAST(appointment_at_utc AS date) > current_date)
  AND p.patient_id NOT IN ((
    SELECT
      patient_id
    FROM
      ophelia-data-marts.ophelia_core.appointments
    WHERE
      appointment_type = 'Initial Visit' ))
ORDER BY
  k.first_lead_date_et DESC;
  `,
  // https://console.cloud.google.com/bigquery?sq=92390295462:41e02d4bb21f4c46b14a1708ac0cdccc
  enrollmentPanel: `
  WITH
  Candidates AS (
  SELECT
    p.most_recent_enrollment_coordinator_employee_id AS enrollmentCoordinatorId,
    p.most_recent_enrollment_coordinator AS enrollmentCoordinatorName,
    CONCAT(p.last_name, ', ', p.first_name) AS patientName,
    p.patient_id AS patientId,
    p.phone_number AS patientPhoneNumber,
    p.patient_status AS patientStatus,
    p.current_patient_state AS patientState,
    p.current_billing_option AS patientBillingOption,
    p.insurance_plan_rollup_name AS patientInsurancePlanName,
    CAST(a.appointment_at_et AS date) AS patientLastWelcomeCallDate,
    CAST(k.most_recent_candidacy_date_et AS date) AS patientMostRecentCandidacyDate,
    row_number () OVER (PARTITION BY p.patient_id ORDER BY a.appointment_at_et DESC ) AS wc_rank
  FROM
    ophelia-data-marts.ophelia_core.patients p
  JOIN
    ophelia-data-marts.ophelia_core.patient_key_dates k
  ON
    p.patient_id = k.patient_id
  JOIN
    ophelia-data-marts.ophelia_core.appointments a
  ON
    p.patient_id = a.patient_id
  WHERE
    p.patient_status IN ('candidate',
      'lead')
    AND p.current_patient_state IN ('PA',
      'NY')
    AND k.most_recent_candidacy_date_et IS NOT NULL
    AND a.appointment_type = 'Free Consultation Call'
    AND NOT a.is_canceled
    AND CAST(a.appointment_at_et AS date) < current_date
    AND p.most_recent_enrollment_coordinator IS NOT NULL
    AND p.patient_id NOT IN (
    SELECT
      patient_id
    FROM
      ophelia-data-marts.ophelia_core.appointments b
    WHERE
      CAST(b.appointment_at_et AS date) >= current_date
      AND NOT b.is_canceled ) QUALIFY wc_rank = 1 ),
  intake AS (
  SELECT
    a.appointment_id,
    a.patient_id,
    a.canceled_at_utc as most_recent_canceled_intake,
    CAST(a.appointment_at_et AS date)AS appointment_at_et,
    CASE
      WHEN a.is_no_show THEN 'Noshowed intake'
      WHEN a.expiration_reason = 'autocanceled' THEN 'Did not confirm intake'
      WHEN a.is_expired THEN 'Did not complete tasks'
    ELSE
    'Asked to be canceled'
  END
    AS intake_outcome
  FROM
    ophelia-data-marts.ophelia_core.appointments a
  WHERE
    appointment_type = 'Initial Visit'
    AND a.is_canceled QUALIFY 1 = row_number () OVER (PARTITION BY a.patient_id ORDER BY a.appointment_at_et DESC ) ),
  jotform AS (
  SELECT
    userId AS patient_id,
    CAST(timestamp AS date) AS created_at_utc
  FROM
    ophelia-data-lake.reverse_etl.winback_backfill_2023_10_13 ),
  emr_winback_issues AS (
  SELECT
    patient_id,
    CAST(created_at_utc AS date) AS created_At_utc
  FROM
    ophelia-data-marts.ophelia_core.emr_issues
  WHERE
    issue_type = 'winback_call' ),
  total_issues AS (
  SELECT
    *
  FROM
    emr_winback_issues
  UNION ALL
  SELECT
    *
  FROM
    jotform ),
  total_issues_2 AS (
  SELECT
    t.*,
    i.most_recent_canceled_intake
  FROM
    total_issues t
  LEFT JOIN
    intake i
  ON
    t.patient_id = i.patient_id),
  count_issues AS (
  SELECT
    patient_id,
    IFNULL(COUNT(*), 0) AS calls
  FROM
    total_issues_2
    where created_at_utc >= ifnull(cast(total_issues_2.most_recent_canceled_intake as date), cast ('2022-01-01' as date))
  GROUP BY
    patient_id ),
  last_issue AS (
  SELECT
    patient_id,
    CAST(created_at_utc AS date) AS last_winback_call,
    row_number () OVER (PARTITION BY patient_id ORDER BY created_at_utc DESC)
  FROM
    total_issues_2
    where created_at_utc >= ifnull(cast(total_issues_2.most_recent_canceled_intake as date), cast ('2022-01-01' as date))
    QUALIFY row_number () OVER (PARTITION BY patient_id ORDER BY created_at_utc DESC) = 1 ),
  winbacks AS (
  SELECT
    c.patient_id,
    c.calls,
    l.last_winback_call
  FROM
    count_issues c
  JOIN
    last_issue l
  ON
    c.patient_id = l.patient_id ),
  base AS (
  SELECT
    c.enrollmentCoordinatorId,
    c.enrollmentCoordinatorName,
    c.patientName,
    c.patientId,
    c.patientState,
    c.patientStatus,
    c.patientPhoneNumber,
    c.patientBillingOption,
    c.patientInsurancePlanName,
    c.patientLastWelcomeCallDate,
    i.appointment_at_et AS most_recent_intake_booked_for,
    coalesce(i.intake_outcome, 'Never booked intake') as IntakeOutcome,
    i.appointment_id,
    i.most_recent_canceled_intake,
    w.calls AS contacts,
    w.last_winback_call AS Last_Winback_Call
  FROM
    candidates c
  LEFT JOIN
    winbacks w
  ON
    c.patientId = w.patient_id
  LEFT JOIN
    intake i
  ON
    c.patientId = i.patient_id )
SELECT
  base.enrollmentCoordinatorId,
  base.enrollmentCoordinatorName,
  base.patientId,
  base.patientName,
  base.patientState,
  base.patientPhoneNumber,
  base.patientStatus,
  base.patientInsurancePlanName,
  base.patientBillingOption AS patientBillingOption,
  base.patientLastWelcomeCallDate,
  case
  when base.IntakeOutcome = 'Noshowed intake' or base.IntakeOutcome = 'Did not confirm intake'  then 5
  when base.IntakeOutcome = 'Did not complete tasks' then 4
  when base.IntakeOutcome = 'Never booked intake' then 3
  when base.IntakeOutcome = 'Asked to be canceled' then 2
  else 1
  end as priority,
  base.appointment_id,
  base.most_recent_canceled_intake,
  base.most_recent_intake_booked_for,
  contacts AS patientContactCount,
  last_winback_call AS patientLastWinbackCallDate
FROM
  base
  order by base.patientLastWelcomeCallDate desc;
  `,
  unpaidPastDueInvoices: `
  -- CTE for filtering and adjusting the due dates of invoices
  WITH
  open_invoices AS (
      SELECT
          invoice_id,
          CASE
              WHEN due_at_utc IS NOT NULL THEN DATETIME(due_at_utc)
              WHEN type = 'copay' THEN DATETIME(TIMESTAMP_ADD(created_at_utc, INTERVAL 10 DAY))
              ELSE DATETIME(created_at_utc)
          END AS actual_due_date,
          patient_id,
          type,
          amount_remaining
      FROM ophelia-data-marts.ophelia_core.invoices i
      WHERE status NOT IN ('void', 'deleted')
        AND SAFE_CAST(amount_remaining AS INT) > 0
  ),

  -- CTE for selecting non-discharged patients
  current_patients AS (
      SELECT
          patient_id,
          patient_status,
          discharge_reason
      FROM ophelia-data-marts.ophelia_sources.source_firestore__patients
      WHERE patient_status != "discharged"
  ),

  -- CTE for identifying overdue copay and subscription invoices
  overdue_invoices AS (
      SELECT
          patient_id,
          invoice_id,
          actual_due_date,
          type
      FROM open_invoices
      LEFT OUTER JOIN current_patients USING(patient_id)
      WHERE (patient_id IS NOT NULL
        AND ((type = 'copay' AND actual_due_date < DATE_SUB(CURRENT_DATETIME(), INTERVAL 90 DAY))
            OR
            (type = 'subscription' AND actual_due_date < DATE_SUB(CURRENT_DATETIME(), INTERVAL 30 DAY)))
      )
  )

  -- Final SELECT to output the data from the last CTE
  SELECT * FROM overdue_invoices;`,
  rudderstackWwwSessions: `
    CREATE TEMP FUNCTION get_url_param(url STRING, param STRING)
    RETURNS STRING
    AS (
        REGEXP_EXTRACT(url, CONCAT(r'[&?]', param, r'=([^&]*)'))
    );


  WITH sessions AS (
    SELECT
      context_session_id as session_id,
      COUNT(DISTINCT p.context_page_path) AS pageviews_count,
      STRING_AGG(DISTINCT p.name, ',') AS pages_viewed,
      MIN(p.timestamp) AS min_time,
      MAX(p.timestamp) AS max_time,
      TIMESTAMP_DIFF(MAX(p.timestamp), MIN(p.timestamp), minute) AS session_time_mins,
      MAX(context_traits_opt_out) as opt_out_value,
      MAX(context_traits_visited_my_ophelia) as visited_my_ophelia,
      MAX(anonymous_id) as anonymous_id,
    FROM \`ophelia-data-lake.rudderstack.pages\` p
    WHERE p.timestamp > TIMESTAMP_SUB(CURRENT_TIMESTAMP(), INTERVAL 1 HOUR)
    AND user_id IS NULL
    AND url LIKE '%https://ophelia.com%'
    GROUP BY context_session_id
    ORDER BY session_time_mins DESC
  ),
  sorted_events as (
    SELECT
    *,
    ROW_NUMBER() OVER (PARTITION BY context_session_id ORDER BY timestamp asc) AS event_num
    FROM \`ophelia-data-lake.rudderstack.pages\`
    WHERE name is not null
  ),

  session_start as (
    SELECT
      url as start_url,
      context_session_id as session_id,
      name as landing_page,
      get_url_param(url, 'gclid') AS gclid,
      get_url_param(url, 'rdt_cid') AS rdt_cid,
      get_url_param(url, 'ttclid') as ttclid,
      get_url_param(url, 'msclkid') as msclkid,
      get_url_param(url, 'utm_source') AS utm_source,
      get_url_param(url, 'utm_campaign') AS utm_campaign,
      get_url_param(url, 'utm_medium') as utm_medium,
      context_user_agent as user_agent
    FROM sorted_events
    WHERE event_num = 1
  )

  SELECT *
  FROM sessions
  INNER JOIN session_start ON session_start.session_id = sessions.session_id where utm_source IS NOT NULL;`,

  credentialedProviders: `
    SELECT *
    FROM
  \`ophelia-data-marts.ophelia_core_intermediate.int_expanded_eligible_ids\`;`,

  payerIdPatientCounts: `
    SELECT * FROM \`ophelia-data-marts.ophelia_core_intermediate.int_payer_id_patient_counts\`;`,

  forecastedCapacityMinusHolds: `
  -- Declare variables for date range
  DECLARE start_date STRING;
  DECLARE end_date STRING;
  
  SET start_date = @startDate;
  SET end_date = @endDate;
  
  -- Construct date spine from the specified date range
  WITH target_dates AS (
    SELECT 
      DATE(calendar_date) AS calendar_date
    FROM 
      UNNEST(GENERATE_DATE_ARRAY(DATE(start_date), DATE(end_date))) AS calendar_date
  ),  
  calculated_capacity AS (
    SELECT 
      employeeId,
      SUM(numMinutesForecastedCapacityMinusHolds) AS numMinutesForecastedCapacityMinusHolds
    FROM
      target_dates td
    JOIN
      ophelia-data-marts.ophelia_core_intermediate.int_acuity_fine_tuning aft
    ON
      td.calendar_date = aft.calendar_date
    GROUP BY 
      1
  )
  SELECT * FROM calculated_capacity;`,
} as const
