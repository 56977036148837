import {
  ArrowLeftIcon,
  Stack,
  TertiaryButton,
  TitleTwo,
  useBanner,
  useLifecycle,
} from '@shared/components'
import React from 'react'
import * as FullStory from '../../../../common/fullstory'
import { useOnboardingDims } from '../../../../common/hooks'
import { ContentToDisplay } from './RescheduleWelcomeCall'

type SuccessfullyRequestedCallMeNowProps = {
  updateContentToDisplay: (contentToDisplay: ContentToDisplay) => void
}

export const SuccessfullyRequestedCallMeNow = ({
  updateContentToDisplay,
}: SuccessfullyRequestedCallMeNowProps) => {
  const { showBanner } = useBanner()
  const { isMobile } = useOnboardingDims()

  const successMessage = 'Expect a call within the next few minutes from (215) 585-2144'

  useLifecycle({
    onMount: () => {
      FullStory.event('[Welcome Call Experiment] Selected CMN Option')

      showBanner({
        type: 'success',
        label: 'Welcome call confirmed',
      })
    },
  })

  return (
    <Stack>
      <TitleTwo>{successMessage}</TitleTwo>
      <TertiaryButton
        leftIcon={<ArrowLeftIcon />}
        onClick={() => updateContentToDisplay('request_cmn')}
        fullWidth={isMobile}
      >
        Back
      </TertiaryButton>
    </Stack>
  )
}
