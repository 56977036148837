import { z } from 'zod'
import { ApiSchemas, stateAbbreviationsEnum } from '../../..'

const ListPayerEnrollmentsReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        oid: z.string(),
        payerName: z.string(),
        status: z.enum(['in_network', 'out_of_network', 'inactive']),
        lineOfBusiness: z.array(
          z.enum([
            'commercial',
            'medicare',
            'medicaid',
            'dual_eligible',
            'chip',
            'exchange',
            'd_snp',
            'other',
          ]),
        ),
        states: z.array(stateAbbreviationsEnum),
        eligiblePayerIds: z.array(z.string()),
        candidPayerIds: z.array(z.string()),
        enrollments: z.array(
          z.object({
            clinicianId: z.string(),
            status: z.enum(['participating', 'not_participating', 'denied']),
            // !TODO make this required eventually
            clinicianName: z.string().optional(),
          }),
        ),
      }),
    ),
  }),
}

const UpdatePayerEnrollmentReqRes = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
    body: z.object({
      status: z.enum(['in_network', 'out_of_network', 'inactive']),
      eligiblePayerIds: z.array(z.string()),
      candidPayerIds: z.array(z.string()),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const PayerEnrollmentsRouteSchemas = {
  'PUT /payer-enrollments/:id': UpdatePayerEnrollmentReqRes,
  'GET /payer-enrollments': ListPayerEnrollmentsReqRes,
} satisfies ApiSchemas
