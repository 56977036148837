import { useForm } from '@mantine/form'
import {
  Alert,
  ArrowRightIcon,
  DailyCalendar,
  DailyCalendarRadioGroup,
  ExternalLinkText,
  PrimaryButton,
  Radio,
  ShieldIcon,
  Stack,
  Text,
  TitleTwo,
  isAnySelected,
  useBanner,
} from '@shared/components'
import { ISOString } from '@shared/types'
import { dayjs, template, toTime } from '@shared/utils'
import last from 'lodash/last'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { appointmentsApi, patientApi } from '../../../../common/api'
import { ContactSupport, SMS_SUPPORT, Skeletons } from '../../../../common/components'
import { CalendarMessage } from '../../../../common/components/CalendarMessage'
import * as FullStory from '../../../../common/fullstory'
import { useMyMutation, useOnboardingDims } from '../../../../common/hooks'
import {
  AppointmentInfo,
  CampaignReferralParamValue,
  ContentToDisplay,
} from './RescheduleWelcomeCall'

const getTitle = ({
  appointment,
  referrer,
}: {
  appointment: AppointmentInfo
  referrer: CampaignReferralParamValue
}) => {
  switch (referrer) {
    case 'preemptive-rescheduling':
      return template("Can't make it {date} at {time}? Select a new time for your welcome call", {
        date: dayjs(appointment.datetime).format('MMM D'),
        time: dayjs(appointment.datetime).format('h:mma z'),
      })
    case 'no-show-rescheduling':
      return 'Select a new time for your welcome call'
  }
}

type RescheduleWelcomeCallCalendarProps = {
  appointmentInfo: AppointmentInfo
  referrer: CampaignReferralParamValue
  updateContentToDisplay: (contentToDisplay: ContentToDisplay) => void
  getUpdatedAppointmentInfo: (datetime: ISOString) => void
}

// @note a lot of the following code is duplicated from WelcomeCallCalendar.tsx
export const RescheduleWelcomeCallCalendar = ({
  appointmentInfo,
  referrer,
  updateContentToDisplay,
  getUpdatedAppointmentInfo,
}: RescheduleWelcomeCallCalendarProps) => {
  const { isMobile } = useOnboardingDims()
  const { showBanner } = useBanner()

  const [date, setDate] = useState(dayjs())

  // @todo move this endpoint to Luna
  const callMeNowQuery = useQuery(
    ['appointmentsApi', 'isConsultationCallQueueAvailable'],
    appointmentsApi.isConsultationCallQueueAvailable,
  )

  const isConsultationCallQueueAvailable: boolean = callMeNowQuery?.data?.isAvailable || false

  const [dateSlotsKey, dateSlotsFn] = patientApi.getQuery(
    'GET /appointments/calendar/free-consultation-call',
  )

  const { data: range, isLoading: isRangeLoading } = useQuery(
    dateSlotsKey,
    async () => {
      const availableRange = await dateSlotsFn()
      const now = dayjs()

      if (availableRange.length === 0) {
        return now.getBetween(now.add(5, 'days'), 'days').map(date => ({ date, available: false }))
      }

      const start = dayjs(availableRange[0]?.date).isToday() ? now : now.add(1, 'day')
      const range = start.getBetween(start.add(5, 'days'), 'days')

      return range.map(date => ({
        date,
        available: availableRange.some(slot => dayjs(slot.date).isSame(date)),
      }))
    },
    {
      cacheTime: toTime('10 sec').ms(),
      onSuccess: dates => {
        const slot = dates.find(slot => slot.available)
        setDate(dayjs(slot?.date ?? dates[0]?.date))

        // If there are no available timeslots, send a FS event
        if (!dates.filter(slot => slot.available).length) {
          FullStory.event('[Welcome Call Experiment] No Availability')
        }
      },
      onError: () => showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
    },
  )

  const isLastDay = dayjs(last(range)?.date).isSame(date, 'day')
  const hasAvailableTimeslots = Boolean(
    range?.find(slot => date.isSame(slot.date, 'day'))?.available,
  )

  const rescheduleAppointmentMutation = useMyMutation('PUT /appointments/:id/reschedule/wc', {
    onSuccess: () => {
      getUpdatedAppointmentInfo(values.datetime as ISOString)

      showBanner({
        type: 'success',
        label: 'Welcome call confirmed',
      })

      FullStory.event('[Welcome Call Experiment] Rescheduled Appointment', {
        numDaysFromToday: dayjs(values.datetime).diff(dayjs(), 'days'),
      })

      updateContentToDisplay('appointment_successfully_rescheduled')
    },
    onError: () => {
      showBanner({ type: 'error', label: 'Something went wrong, please try again' })
    },
  })

  const { data: timeslots, isLoading: isTimeSlotsLoading } = useQuery(
    ...patientApi.getQuery('GET /appointments/calendar/free-consultation-call/:date', {
      params: { date: date.format('YYYY-MM-DD') },
    }),
    {
      cacheTime: toTime('10 sec').ms(),
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      // Don't fetch for last date, we show a message of booking too far
      enabled: hasAvailableTimeslots && !isLastDay,
    },
  )

  const { clearErrors, getInputProps, setValues, validate, values } = useForm<{
    datetime: string
  }>({
    initialValues: {
      datetime: '',
    },
    validate: {
      datetime: isAnySelected(timeslots?.map(slot => slot.time) ?? [], 'Required'),
    },
  })

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }

    void rescheduleAppointmentMutation.mutate({
      params: { id: `${appointmentInfo.id}` },
      data: { datetime: values.datetime as ISOString },
    })

    FullStory.event('[Welcome Call Experiment] Rescheduled Appointment', {
      numDaysFromToday: dayjs(values.datetime).diff(dayjs(), 'days'),
    })
  }

  // Track when users select an appointment time
  useEffect(() => {
    if (values.datetime) {
      FullStory.event('[Welcome Call Experiment] Selected Appointment Time', {
        datetime: values.datetime,
      })
    }
  }, [values.datetime])

  if (isRangeLoading) {
    return <Skeletons />
  }

  return (
    <Stack>
      <TitleTwo>{getTitle({ appointment: appointmentInfo, referrer })}</TitleTwo>
      <Text>
        After your welcome call, we&apos;ll schedule your first visit with a licensed clinician. No
        charge if we&apos;re not a fit.
      </Text>
      <DailyCalendar
        nextDayPulse={timeslots?.length === 0}
        previousDayPulse={isLastDay}
        value={date.toString()}
        range={range?.map(slot => slot.date) ?? []}
        onChange={value => {
          clearErrors()
          setValues({ datetime: '' })
          setDate(dayjs(value))

          // Track when users toggle the calendar date
          FullStory.event('[Welcome Call Experiment] Toggled Calendar Date', {
            date: value,
          })
        }}
      >
        {isLastDay ? (
          <CalendarMessage>
            Sorry, we can&apos;t book this far out right now. If you can&apos;t find a time, text{' '}
            <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>(215) 585-2144</ExternalLinkText>
          </CalendarMessage>
        ) : (
          <DailyCalendarRadioGroup
            size={6}
            loading={isTimeSlotsLoading}
            empty={<CalendarMessage>Sorry, we&apos;re booked on this day.</CalendarMessage>}
            {...getInputProps('datetime')}
          >
            {timeslots?.map(timeslot => {
              return (
                <Radio
                  key={timeslot.time}
                  value={timeslot.time}
                  label={dayjs(timeslot.time).format('h:mma z')}
                />
              )
            })}
          </DailyCalendarRadioGroup>
        )}
      </DailyCalendar>
      {isConsultationCallQueueAvailable && (
        <Alert
          variant='primary'
          title='Have 15 minutes to talk now?'
          icon={<ShieldIcon />}
          actionIcon={<ArrowRightIcon />}
          onClick={() => updateContentToDisplay('request_cmn')}
        >
          We have someone available to give you a call to answer your questions right now.
        </Alert>
      )}
      <PrimaryButton
        onClick={() => onSubmit()}
        fullWidth={isMobile}
        loading={rescheduleAppointmentMutation.isLoading}
      >
        Reschedule welcome call
      </PrimaryButton>
      <ContactSupport text="Don't see a time that works?" />
    </Stack>
  )
}
