import { z } from 'zod'
import { DAYS_READONLY } from '../../../account'
import { descriptivePatientAttributeKeys, experimentNames } from '../../../experiments'
import { ApiSchemas } from '../../utils'

const PostAvailabilityReqResSchemas = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
    body: z.object({
      availability: z.object({
        morning: z.array(z.enum(DAYS_READONLY)),
        afternoon: z.array(z.enum(DAYS_READONLY)),
        evening: z.array(z.enum(DAYS_READONLY)),
      }),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const GetAvailabilityReqResSchemas = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      availability: z
        .object({
          morning: z.array(z.enum(DAYS_READONLY)),
          afternoon: z.array(z.enum(DAYS_READONLY)),
          evening: z.array(z.enum(DAYS_READONLY)),
        })
        .nullable(),
    }),
  }),
}

const PostPatientSettingsReqResSchemas = {
  req: z.object({
    body: z.object({
      /*
       * If we want to update other patient settings, we can add additional
       * keys below `experiments`
       */
      experiments: z.object({
        experimentNames: z.array(z.enum(experimentNames)),
        patientAttributes: z
          .array(
            z.object({
              attributeName: z.enum(descriptivePatientAttributeKeys),
              attributeValue: z.union([z.string(), z.number(), z.boolean()]),
            }),
          )
          .optional(),
      }),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const PostAnonymousCohortReqResSchemas = {
  req: z.object({
    body: z.object({
      experimentName: z.enum(experimentNames),
      anonymousId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      cohort: z.enum(['test', 'control', 'not_sorted']),
    }),
  }),
}

export const MyAvailabilityApi = {
  'POST /patients/:patientId/settings/availability': PostAvailabilityReqResSchemas,
  'GET /patients/:patientId/settings/availability': GetAvailabilityReqResSchemas,
  'POST /settings': PostPatientSettingsReqResSchemas,
  'POST /public/settings/cohort': PostAnonymousCohortReqResSchemas,
} satisfies ApiSchemas
