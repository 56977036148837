/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-definitions */

const events = ['load', 'identify', 'sidentify', 'track', 'page', 'on', 'off'] as const

declare global {
  interface Window {
    _cio: any
  }
}

/**
 * https://customer.io/docs/journeys/in-app-getting-started/
 * Note: This should only be called once top level in the app
 * Customer.io recommends adding this to the top level html file, but we decided to make it a function
 * here so that we can access our config variables
 */
export const initCustomerIO = ({ siteId }: { siteId: string }) => {
  const _cio: any = window._cio ?? []

  const a = function (f: (typeof events)[number]) {
    return function () {
      // eslint-disable-next-line prefer-rest-params
      _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)))
    }
  }
  for (let c = 0; c < events.length; c++) {
    _cio[events[c]!] = a(events[c]!)
  }

  window._cio = _cio

  const t = document.createElement('script')
  const s = document.getElementsByTagName('script')[0]!

  t.async = true
  t.id = 'cio-tracker'
  t.setAttribute('data-site-id', siteId)
  t.setAttribute('data-use-array-params', 'true')
  t.setAttribute('data-use-in-app', 'true')
  t.src = 'https://assets.customer.io/assets/track.js'
  s.parentNode?.insertBefore(t, s)
}

export const cio = {
  identify: (args: { id: string }) => {
    window._cio?.identify(args)
  },
  page: () => {
    // calling _cio.page() with no parameters will send a page view event to customer.io with the current url
    window._cio?.page()
  },
}
