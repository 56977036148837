import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TitleTwo,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import * as FullStory from '../../../../../src/common/fullstory'
import { isRequired } from '../../../forms'
import { useAuth, useMyMutation } from '../../../hooks'
import { sendIdentifyEvent, sendTrackEvent } from '../../../rudderstack'
import { getSessionStorageItem } from '../../../storage'
import { WidgetProps } from '../PromptContentWidget'

type Form = {
  prescribedBy: PromptResponseContextKey | undefined
}

const MEDICAL_PROFESSIONAL =
  'suboxone_prescribed_by_medical_professional' as PromptResponseContextKey
const NONE = 'suboxone_prescribed_by_none' as PromptResponseContextKey
export const SuboxonePrescribedBy = ({ response, onBack, onSubmit }: WidgetProps) => {
  const { currentUser } = useAuth()

  const patientState =
    getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state

  // Update the patient's attributes so that we can sort them into a test vs. control cohort
  const patientSettingsMutation = useMyMutation('POST /settings')

  const form = useForm<Form>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      prescribedBy: response?.value.contextKey as PromptResponseContextKey | undefined,
    },
    validate: {
      prescribedBy: validateWith(isRequired),
    },
  })

  const submitHandler = () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }
    if (!form.values.prescribedBy) {
      return
    }

    const response = form.values.prescribedBy

    if (patientState) {
      const shouldIdentifyAsCareTransfer = form.values.prescribedBy === MEDICAL_PROFESSIONAL

      if (shouldIdentifyAsCareTransfer) {
        FullStory.event('Patient Is In Care Transfer Segment')
      }

      /*
       * Update the cohort regardless of the response because a patient may
       * have identified as a care transfer before changing their response,
       * so we need a way to update the response + cohort
       */
      patientSettingsMutation.mutate(
        {
          data: {
            experiments: {
              experimentNames: ['care-transfer-cohort-split'],
              patientAttributes: [
                {
                  attributeName: 'is_care_transfer',
                  attributeValue: shouldIdentifyAsCareTransfer,
                },
              ],
            },
          },
        },
        {
          onSuccess: () => {
            void currentUser?.refetch()

            sendTrackEvent('Care Transfer Response', {
              isCareTransfer: shouldIdentifyAsCareTransfer,
            })

            sendIdentifyEvent({
              // We can assume that the uid is present because the mutation would fail otherwise
              uid: currentUser?.data?.oid || '',
              data: {
                careTransfer: shouldIdentifyAsCareTransfer,
              },
            })

            // Submit prompt response with the patient's state which is required for booking an intake later
            onSubmit({
              value: {
                contextKey: response,
              },
              patient_address_state: {
                contextKey: 'custom',
                value: patientState,
              },
            })
          },
        },
      )
    } else {
      onSubmit({
        value: {
          contextKey: response,
        },
      })
    }
  }

  return (
    <Stack spacing='lg' test-id='content:suboxone-prescribed-by'>
      <TitleTwo>Is your Suboxone prescribed by a medical professional?</TitleTwo>
      <RadioGroup {...form.getInputProps('prescribedBy')}>
        <Radio
          test-id='radio:suboxone-prescribed-by:yes'
          label='Yes'
          value={MEDICAL_PROFESSIONAL}
          disabled={patientSettingsMutation.isLoading}
        />
        <Radio
          test-id='radio:suboxone-prescribed-by:no'
          label='No'
          value={NONE}
          disabled={patientSettingsMutation.isLoading}
        />
      </RadioGroup>
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton
              test-id='button:next@mobile'
              fullWidth
              onClick={submitHandler}
              loading={patientSettingsMutation.isLoading}
              disabled={patientSettingsMutation.isLoading}
            >
              Next
            </PrimaryButton>
            {onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
                disabled={patientSettingsMutation.isLoading}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Group position='apart'>
            {onBack && (
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
                disabled={patientSettingsMutation.isLoading}
              >
                Back
              </TertiaryButton>
            )}
            <Group position='right'>
              <PrimaryButton
                test-id='button:next@desktop'
                onClick={submitHandler}
                loading={patientSettingsMutation.isLoading}
                disabled={patientSettingsMutation.isLoading}
              >
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
