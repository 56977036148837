import { useForm } from '@mantine/form'
import {
  DailyCalendar,
  DailyCalendarRadioGroup,
  Radio,
  Stack,
  Text,
  TitleTwo,
  isAnySelected,
  useBanner,
} from '@shared/components'
import { PromptResponsePayload } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import last from 'lodash/last'
import React, { useState } from 'react'
import { useMyQuery } from '../../../../hooks'
import { getSessionStorageItem } from '../../../../storage'
import { CalendarMessage } from '../../../CalendarMessage'
import { Skeletons } from '../../../Skeletons'
import { PromptContentNavigation } from '../../PromptContentNavigation'
import { WidgetProps } from '../../PromptContentWidget'

type IntakeVisitForm = {
  datetime: string
}

const START_DATE_DAYS_AFTER_RELEASE = 5
const RANGE_DURATION = 5

export const CorrectionalFacilityIntakeVisitSchedule = ({ onBack, onSubmit }: WidgetProps) => {
  const releaseDateString = getSessionStorageItem('referral_workflow_date') ?? ''
  const currentUserState = getSessionStorageItem('referral_workflow_state') ?? ''

  if (onBack && (!releaseDateString || !currentUserState)) {
    onBack()
  }

  const { showBanner, hideBanner } = useBanner()

  // Date range between 5 days after release day and 10 days after release date
  const startDate = releaseDateString
    ? dayjs(releaseDateString).add(START_DATE_DAYS_AFTER_RELEASE, 'days')
    : null
  const endDate = startDate ? startDate.add(RANGE_DURATION, 'days') : null

  const [date, setDate] = useState(startDate)
  const [range, setRange] = useState<{ date: string; available: boolean }[]>([])

  const calendarsQuery = useMyQuery(
    'GET /public/calendars',
    {
      query: {
        state: currentUserState,
      },
    },
    {
      enabled: Boolean(currentUserState),
    },
  )

  const calendarIdsData = calendarsQuery?.data?.data?.calendarIds || []

  const { isLoading: isRangeLoading } = useMyQuery(
    'GET /public/appointments/calendar/intake',
    {
      query: {
        calendarIds: calendarIdsData,
        state: currentUserState ?? '',
        start: startDate?.toISOString() ?? '',
        duration: RANGE_DURATION.toString(),
      },
    },
    {
      cacheTime: toTime('10 sec').ms(),
      enabled: calendarIdsData.length > 0,
      onSuccess: data => {
        const availableRange = data?.data
        if (startDate && endDate) {
          let processedRange: { date: string; available: boolean }[]

          if (!availableRange || availableRange.length === 0) {
            processedRange = startDate
              .getBetween(endDate, 'days')
              .map(date => ({ date, available: false }))
          } else {
            const range = startDate.getBetween(endDate, 'days')

            processedRange = range.map((date: string) => ({
              date,
              available: availableRange.some(slot => dayjs(slot.date).isSame(date)),
            }))
          }

          setRange(processedRange)

          // Find the first available date or default to the first date in the range
          const firstAvailableSlot = processedRange.find(slot => slot?.available)
          const initialDate = firstAvailableSlot?.date || processedRange[0]?.date

          if (initialDate) {
            setDate(dayjs(initialDate))
          }
        } else {
          setRange([])
          setDate(null)
        }
      },
      onError: () => {
        showBanner({ type: 'error', label: 'Something went wrong, try again later' })
      },
    },
  )

  const isLastDay = dayjs(last(range)?.date).isSame(date, 'day')
  const isAvailable = Boolean(
    range?.find((slot: { date: string; available: boolean }) => date?.isSame(slot.date, 'day'))
      ?.available,
  )

  const {
    data: intakeTimes,
    isLoading: isIntakeTimesLoading,
    refetch: refetchIntakeTimes,
  } = useMyQuery(
    'GET /public/appointments/calendar/intake/:date',
    {
      params: { date: date?.format('YYYY-MM-DD') ?? '' },
      query: {
        start: date?.isValid() ? dayjs(date)?.toISOString() : '',
        calendarIds: calendarIdsData,
        state: currentUserState ?? '',
      },
    },
    {
      cacheTime: toTime('10 sec').ms(),
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      // Don't fetch for last date, we show a message of booking too far
      enabled: calendarIdsData.length > 0 && isAvailable && !isLastDay,
      onError: () => {
        showBanner({ type: 'error', label: 'Something went wrong, try again later' })
      },
    },
  )

  const timeslots = intakeTimes?.data

  const form = useForm<IntakeVisitForm>({
    initialValues: {
      datetime: '',
    },
    validate: {
      datetime: isAnySelected(timeslots?.map(slot => slot.time) ?? [], 'Required'),
    },
  })

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    const calendarId = timeslots?.find(slot => slot.time === form.values.datetime)?.calendarIDs[0]

    if (!calendarId) {
      showBanner({
        type: 'error',
        label: 'Something went wrong, please select a different appointment time',
      })
      return
    }

    const payload: PromptResponsePayload<'patient_referral_form'> = {
      value: {
        contextKey: 'custom',
      },
      schedule_intake_visit: {
        contextKey: 'custom',
        value: form.values.datetime,
      },
      schedule_intake_visit_calendar: {
        contextKey: 'custom',
        value: String(calendarId),
      },
      schedule_intake_visit_timezone: {
        contextKey: 'custom',
        value: dayjs.tz.guess(),
      },
    }

    onSubmit(payload, { onError })
  }

  const onError = async () => {
    showBanner({ type: 'error', label: 'Something went wrong, try again later' })
    await refetchIntakeTimes()
  }

  if (isRangeLoading || calendarsQuery.isLoading) {
    return <Skeletons />
  }

  return (
    <Stack spacing='md' test-id='content:correctional-facility-intake-visit-schedule'>
      <TitleTwo>{`Choose a time for the patient's 60-minute intake visit with a licensed clinician`}</TitleTwo>
      <Text>During this visit, the referral will receive a prescription, if eligible.</Text>

      <DailyCalendar
        nextDayPulse={timeslots?.length === 0}
        previousDayPulse={isLastDay}
        value={date?.toString() ?? ''}
        range={range?.map(slot => slot.date) ?? []}
        onChange={value => {
          form.clearErrors()
          form.setValues({ datetime: '' })
          setDate(dayjs(value))
          hideBanner()
        }}
      >
        {isLastDay ? (
          <CalendarMessage>
            {`Sorry, we don't have availability right now. Please email partnerships@ophelia.com or
            check back again later.`}
          </CalendarMessage>
        ) : (
          <DailyCalendarRadioGroup
            size={6}
            loading={isIntakeTimesLoading}
            empty={<CalendarMessage>Sorry, we&apos;re booked on this day.</CalendarMessage>}
            {...form.getInputProps('datetime')}
          >
            {timeslots?.map(timeslot => {
              return (
                <Radio
                  key={timeslot.time}
                  value={timeslot.time}
                  label={dayjs(timeslot.time).format('h:mma z')}
                />
              )
            })}
          </DailyCalendarRadioGroup>
        )}
      </DailyCalendar>
      <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
    </Stack>
  )
}
