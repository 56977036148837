import {
  ExperimentCohort,
  ExperimentName,
  ISOString,
  IntendedPaymentMethod,
  PasswordType,
  Patient,
} from '.'
import { AccountSettings } from './account'
import { PaymentCard } from './chargebee'

export type PaymentCardParams = {
  card: PaymentCard
}

export type InsurancePayer = { name: string; payerId: string }

export type CurrentUserPatient = Omit<Patient, 'account' | 'discharge'> & {
  account: {
    hasPassword: boolean
    passwordType: PasswordType
    profileComplete: boolean
    settings?: AccountSettings | undefined
    timezone: string
    waitingForWelcomeCall: boolean
    requiresOnboarding: boolean
    readyForPortal: boolean
    zoomLastTested: string | undefined
    // @deprecated Nov 2024
    careTransfer?:
      | {
          isCareTransfer: boolean
          careTransferExperimentGroup?: 'test_cohort' | 'control_cohort'
        }
      | undefined
  }
  experiments?: {
    [key in ExperimentName]: {
      cohort: ExperimentCohort
      createdAt: ISOString
      deletedAt?: ISOString
    }
  }
  files: {
    idCardFront?: string | undefined
    idCardBack?: string | undefined
  }
  discharge?: {
    date: string
    displayDischargeBanner: boolean
  }
  onlyOnePaymentMethodAllowed: boolean
  insuranceData: {
    intendedPaymentMethod: IntendedPaymentMethod | undefined
  }
}

export type PatientProvidedInsuranceData = {
  insuranceInfo: PatientInsurance
  secondaryInsuranceInfo?: PatientInsurance | undefined
}

export type PatientInsurance = {
  provider: string
  eligiblePayerId: string
  memberId: string
  groupId: string
  rxBin: string
  cardholderFirstName: string
  cardholderLastName: string
  cardholderBirthday: string
  insuranceCardFront?: string | undefined
  insuranceCardBack?: string | undefined
  patientIsPrimarySubscriber: boolean
  primarySubscriberFirstName: string
  primarySubscriberLastName: string
  primarySubscriberBirthday: string
}

export type PatientAttributes = {
  gclid?: string
}

export type TextMessageConsentSource =
  | 'patient_app_login_page'
  | 'twilio_ivr'
  | 'twilio_ivr_call_us_now'
  | 'twilio_sms'
  | 'offline_forms'

export type Consents = {
  financialConsent?: boolean
  treatmentConsent?: boolean
  privacyPolicy?: boolean
  telehealthInformedConsent?: boolean
  termsOfUse?: boolean
  tpoConsent?: boolean
  textMessageConsent?: boolean
  textMessageConsentSource?: TextMessageConsentSource
  textMessageConsentDatetime?: ISOString
}

export type ExternalAttributes = {
  springHealth?: {
    id: string
    isBilledBySpringHealth: boolean
  }
  paDoc?: {
    estimatedReleaseDate: string
    email: string
    phone: string
  }
}
