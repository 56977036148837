import { WorkflowType } from '../workflows'
import { AsqFormPromptContextKey, AsqFormPromptResponseContextKey } from './asqForm'
import { Barc10FormPromptContextKey, Barc10FormPromptResponseContextKey } from './barc10form'
import { Gad7FormPromptContextKey, Gad7FormPromptResponseContextKey } from './gad7Form'
import {
  MedicalIntakeFormPromptContextKey,
  MedicalIntakeFormPromptResponseContextKey,
} from './medicalIntakeForm'
import { NotReadyPromptContextKey, NotReadyPromptResponseContextKey } from './notReady'
import {
  Phq8FormPromptContextKey,
  Phq8FormPromptResponseContextKey,
  Phq9FormPromptContextKey,
  Phq9FormPromptResponseContextKey,
} from './phq9Form'
import {
  PatientReferralFormPromptContextKey,
  PatientReferralFormPromptResponseContextKey,
  SpringHealthReferralFormPromptContextKey,
} from './referrals'
import { WelcomeCallPromptContextKey, WelcomeCallPromptResponseContextKey } from './welcomeCall'

/** Unique Reference to a prompt's meaning, separate from how it was phrased or which workflows it lives in */
export type PromptContextKey<T extends WorkflowType = WorkflowType> = T extends 'onboarding'
  ? WelcomeCallPromptContextKey
  : T extends 'medical_intake_form'
  ? MedicalIntakeFormPromptContextKey
  : T extends 'patient_referral_form'
  ? PatientReferralFormPromptContextKey
  : T extends 'patient_referral_form_partner'
  ? PatientReferralFormPromptContextKey
  : T extends 'patient_referral_form_partner_with_patient'
  ? PatientReferralFormPromptContextKey
  : T extends 'patient_referral_form_spring_health'
  ? SpringHealthReferralFormPromptContextKey
  : T extends 'phq_9_form'
  ? Phq9FormPromptContextKey
  : T extends 'phq_8_form'
  ? Phq8FormPromptContextKey
  : T extends 'gad_7_form'
  ? Gad7FormPromptContextKey
  : T extends 'barc_10_form'
  ? Barc10FormPromptContextKey
  : T extends 'asq_form'
  ? AsqFormPromptContextKey
  : T extends 'not_ready_flow'
  ? NotReadyPromptContextKey
  : never

/** Unique Reference to a prompt answer's meaning */
export type PromptResponseContextKey<T extends WorkflowType = WorkflowType> = T extends 'onboarding'
  ? WelcomeCallPromptResponseContextKey
  : T extends 'medical_intake_form'
  ? MedicalIntakeFormPromptResponseContextKey
  : T extends 'patient_referral_form'
  ? PatientReferralFormPromptResponseContextKey
  : T extends 'phq_9_form'
  ? Phq9FormPromptResponseContextKey
  : T extends 'phq_8_form'
  ? Phq8FormPromptResponseContextKey
  : T extends 'gad_7_form'
  ? Gad7FormPromptResponseContextKey
  : T extends 'barc_10_form'
  ? Barc10FormPromptResponseContextKey
  : T extends 'asq_form'
  ? AsqFormPromptResponseContextKey
  : T extends 'not_ready_flow'
  ? NotReadyPromptResponseContextKey
  : never

export * from './referrals'
export * from './welcomeCall'

export { BARC10_FORM_PROMPT_RESPONSE_CONTEXT_KEYS } from './barc10form'
export { GAD7_FORM_PROMPT_RESPONSE_CONTEXT_KEYS } from './gad7Form'
export {
  PHQ8_FORM_PROMPT_RESPONSE_CONTEXT_KEYS,
  PHQ9_FORM_PROMPT_RESPONSE_CONTEXT_KEYS,
} from './phq9Form'
