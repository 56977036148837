import { Stack, useBanner, useLifecycle } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePortalDims } from '../../common/hooks'
import { routes } from '../../common/routes'
import { sendTrackEvent } from '../../common/rudderstack'
import { StartTasksSection } from './StartTasksSection'

export const WelcomeChatCompleted = () => {
  const { showBanner } = useBanner()
  const navigate = useNavigate()
  const { isMobile } = usePortalDims()

  useLifecycle({
    onMount: () => {
      showBanner({ type: 'success', label: 'Welcome chat completed' })
    },
  })

  const handleStartTasks = () => {
    sendTrackEvent('[Welcome Chat] Start Tasks Button Clicked')
    navigate(routes.portal.index)
  }

  return (
    <Stack test-id='page:welcome-chat-completed'>
      <StartTasksSection isMobile={isMobile} handleStartTasks={handleStartTasks} />
    </Stack>
  )
}
