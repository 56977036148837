import { z } from 'zod'
import { ISOStringSchema } from '../../../dates'
import { ApiSchemas } from '../../utils'

const GetAppointmentByIdReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      id: z.number(),
    }),
  }),
}

const UpdateAppointmentByIdForCallMeNowReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const CreateAppointmentByIdForCallMeNowReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      newApptId: z.string(),
    }),
  }),
}

// @welcomeCallReschedulingExperiment
const RescheduleWelcomeCallAppointmentByIdReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
    body: z.object({
      datetime: ISOStringSchema,
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

// @welcomeCallReschedulingExperiment
const GetLatestWelcomeCallAppointmentReqResSchemas = {
  req: z.object({
    query: z.object({
      /*
       * This is a weird pattern for the portal, since we normally always know
       * the patientId. However, in this case, we are using an unauthenticated
       * link to allow leads to reschedule their welcome calls, so we don't have
       * access to the patientId in res.locals. However, the client does know
       * the patientId because it's being passed to the frontend through another
       * API call. Therefore, we can use the patientId to get the latest welcome
       * call appointment for that patient.
       */
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      id: z.number().nullable(),
    }),
  }),
}

const CancelAppointmentByIdReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const TimeSlotSchema = z.object({
  time: z.string(),
  calendarIDs: z.array(z.number()),
})

export const DateSlotSchema = z.object({
  date: z.string(),
  calendarIDs: z.array(z.number()),
})

const GetIntakeDatesAnonymouslyReqResSchema = {
  req: z.object({
    query: z.object({
      state: z.string(),
      calendarIds: z.array(z.string()),
      start: z.string().optional(),
      duration: z.string().optional(),
    }),
  }),
  res: z.object({
    data: z.array(DateSlotSchema),
  }),
}

const GetIntakeTimesAnonymouslyReqResSchema = {
  req: z.object({
    params: z.object({
      date: z.string(),
    }),
    query: z.object({
      state: z.string(),
      calendarIds: z.array(z.string()),
      start: z.string(),
    }),
  }),
  res: z.object({
    data: z.array(TimeSlotSchema),
  }),
}

export const MyAppointmentsApi = {
  'GET /appointments/:id': GetAppointmentByIdReqResSchemas,
  'PUT /appointments/:id/call-me-now': UpdateAppointmentByIdForCallMeNowReqResSchemas,
  'POST /appointments/:id/call-me-now': CreateAppointmentByIdForCallMeNowReqResSchemas,
  // @welcomeCallReschedulingExperiment
  'PUT /appointments/:id/reschedule/wc': RescheduleWelcomeCallAppointmentByIdReqResSchemas,
  'GET /public/appointments/calendar/intake': GetIntakeDatesAnonymouslyReqResSchema,
  'GET /public/appointments/calendar/intake/:date': GetIntakeTimesAnonymouslyReqResSchema,
  'GET /appointments/wc/latest': GetLatestWelcomeCallAppointmentReqResSchemas,
  'PUT /appointments/:id/cancel': CancelAppointmentByIdReqResSchemas,
} satisfies ApiSchemas
