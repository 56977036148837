import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  TitleTwo,
  skipIfOtherField,
  useLifecycle,
  validateWith,
} from '@shared/components'
import { PromptResponsePayload } from '@shared/types'
import { dayjs, validCharacters } from '@shared/utils'
import React, { useMemo } from 'react'
import * as FullStory from '../../../../../src/common/fullstory'
import { isAtleastOneWord, isPhone, isRequired } from '../../../forms'
import { useAuth, useFlags } from '../../../hooks'
import { useCallMeNowQueueStatus } from '../../../hooks/use-call-me-now-queue-status'
import { sendPageEvent } from '../../../rudderstack'
import { getSessionStorageItem } from '../../../storage'
import { WidgetProps } from '../PromptContentWidget'

type CallMeNowProps = WidgetProps & {
  disableCallMeNow: () => void
  reconnect?: boolean
}

export const CallMeNowPage = ({ reconnect, ...props }: CallMeNowProps) => {
  const { currentUser, isAuthorized } = useAuth()
  const { isQueueAvailable } = useCallMeNowQueueStatus()
  const { hideScheduledWelcomeCallsWhileQueueIsOpen } = useFlags()

  const { values, validate, getInputProps } = useForm({
    initialValues: {
      // Force with custom phone if not authorized
      withCustomPhone: !isAuthorized,
      // The following initial values should be blank to protect PHI
      firstName: '',
      lastName: '',
      phone: '',
      ext: '',
    },
    validate: {
      firstName: validateWith(isRequired, isAtleastOneWord),
      lastName: validateWith(isRequired, isAtleastOneWord),
      phone: validateWith(skipIfOtherField('withCustomPhone', 'is', false), isRequired, isPhone),
    },
  })

  const shouldShowScheduleCallButton = () => {
    if (
      hideScheduledWelcomeCallsWhileQueueIsOpen.includes(
        getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state,
      )
    ) {
      return !isQueueAvailable
    }
    return true
  }

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }

    const payload: PromptResponsePayload<'onboarding'> = {
      value: {
        contextKey: 'custom',
      },
      schedule_welcome_call_type: {
        contextKey: 'call_me_now',
      },
      schedule_welcome_call_reconnect: {
        contextKey: 'custom',
        value: currentUser?.data?.statuses?.patient === 'discharged',
      },
      schedule_welcome_call_first_name: {
        contextKey: 'custom',
        value: values.firstName,
      },
      schedule_welcome_call_last_name: {
        contextKey: 'custom',
        value: values.lastName,
      },
      schedule_welcome_call_timezone: {
        contextKey: 'custom',
        value: dayjs.tz.guess(),
      },
      schedule_welcome_call_address_state: {
        contextKey: 'custom',
        // Reconnect has no residence_state
        value: getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state || '',
      },
    }

    if (values.withCustomPhone) {
      payload.schedule_welcome_call_phone = {
        contextKey: 'custom',
        value: values.phone,
      }
      payload.schedule_welcome_call_ext = {
        contextKey: 'custom',
        value: values.ext,
      }
    }

    props.onSubmit(payload)
  }

  useLifecycle({
    onMount: () => {
      sendPageEvent('Call Me Now')
      FullStory.event('Call me now CTA is visible')
    },
  })

  const title = useMemo(() => {
    if (reconnect) {
      return `Let's Reconnect`
    }

    return `Want to talk right now?`
  }, [reconnect])

  const description = useMemo(() => {
    if (reconnect) {
      return `We'll give you a call in the next few minutes to determine how we can get you started with Ophelia again.`
    }

    if (isAuthorized) {
      return `Share your name and we'll give you a call in the next few minutes.`
    }

    return `Share your name and we'll give you a call in the next few minutes. Since you don't have your phone on you, we'll need a phone number where you can currently receive a call.`
  }, [isAuthorized, reconnect])

  return (
    <Stack spacing='lg'>
      <Stack>
        <TitleTwo>{title}</TitleTwo>
        <Text>{description}</Text>
      </Stack>
      <Stack>
        <TextInput label='First name' placeholder='First name' {...getInputProps('firstName')} />
        <TextInput label='Last name' placeholder='Last name' {...getInputProps('lastName')} />
        {values.withCustomPhone && (
          <Stack>
            <PhoneInput label='Where can we call you?' {...getInputProps('phone')} />
            <TextInput
              label='Extension (optional)'
              placeholder='e.g. 104'
              formatter={validCharacters.numbers}
              {...getInputProps('ext')}
            />
          </Stack>
        )}
      </Stack>
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton fullWidth onClick={onSubmit}>
              Request a call now
            </PrimaryButton>
            {shouldShowScheduleCallButton() && (
              <TertiaryButton fullWidth onClick={props.disableCallMeNow}>
                Schedule a call for later
              </TertiaryButton>
            )}
            {props.onBack && (
              <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={props.onBack}>
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Group position='apart'>
            {props.onBack && (
              <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={props.onBack}>
                Back
              </TertiaryButton>
            )}
            <Group position='right'>
              {shouldShowScheduleCallButton() && (
                <SecondaryButton onClick={props.disableCallMeNow}>
                  Schedule a call for later
                </SecondaryButton>
              )}
              <PrimaryButton onClick={onSubmit}>Request a call now</PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
